import { SelectPopover } from 'components/popovers/SelectPopover/SelectPopover';
import { CitiesContext } from 'context';
import { useContext } from 'react';
import { reachGoal } from 'utils/metrika';

export const CityPopover = ({ selectedValue, children }) => {
  const { cities } = useContext(CitiesContext);

  return (
    <SelectPopover
      onChange={value => {
        if (selectedValue !== value) {
          const selectedCityConfig = cities.find(({ slug }) => value === slug);

          const hostname = [];

          if (selectedCityConfig.slug && selectedCityConfig.slug !== 'sochi') {
            hostname.push(selectedCityConfig.slug);
          }

          hostname.push(process.env.NEXT_PUBLIC_DOMAIN);

          reachGoal('city_changed', {
            city: selectedCityConfig.title
          });

          window.location = `https://${hostname.join('.')}${window.location.pathname}`;
        }
      }}
      selectedValue={selectedValue}
      values={
        cities.map(config => ({
          key: config.slug,
          title: config.title
        }))
      }
    >
      { children }
    </SelectPopover>
  )
}
