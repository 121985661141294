import 'time-picker.css';

import cn from 'classnames';
import moment from 'moment';
import TimePicker from 'rc-time-picker';

export const InputTime = ({ min, onChange, value, className }) => {
  return (
    <TimePicker
      value={value}
      onChange={(newValue) => {
        onChange(newValue);
      }}
      className={cn('h-full', className)}
      defaultValue={moment().set('hours', 12).set('minutes', 0)}
      showSecond={false}
    />
  )
}
