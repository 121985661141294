import { Transition } from '@headlessui/react'
import { useDebouncedState } from '@react-hookz/web';
import { Input } from 'components/common';
import { CitiesContext } from 'context';
import { useLocationSuggestions } from 'query';
import React, { useContext, useState } from 'react';
import { getAddressString } from 'utils/address';

export function LocationSuggester({ autoFocus, name, zIndex = 1, id, value, onChange, className, streetOnly, withoutCity = false, onSelect, placeholder = 'Адрес' }) {
  const { currentCity } = useContext(CitiesContext);
  const [ query, setQuery ] = useDebouncedState(value, 800, 1000);
  const { isLoading, isError, data: suggestions } = useLocationSuggestions(query, currentCity.id);
  const [ isResultsVisible, setResultsVisible ] = useState(false);

  console.log('[LocationSuggester]:', currentCity.id, withoutCity, streetOnly);

  return (
    <div className="relative" style={{ zIndex }}>
      <Input
        autoComplete="off"
        className={className}
        id={id}
        name={name}
        autoFocus={false}
        type="text"
        placeholder={placeholder}
        value={value}
        onBlur={() => setResultsVisible(false)}
        onChange={e => {
          setQuery(e.target.value);

          if (onChange) {
            onChange(e);
          }
        }}
        onFocus={() => setResultsVisible(true)}
      />
      <Transition
        show={!isLoading && !isError && Boolean(suggestions.length) && isResultsVisible && Boolean(query.length)}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity delay-500 duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="transition-opacity absolute bg-white left-0 border top-12 px-2 py-3 rounded-xl w-full shadow-xl">
          {
            suggestions && suggestions.map((result, index) => {
              return (
                <div
                  key={index}
                  onClick={() => onSelect && onSelect(result, getAddressString(result, streetOnly, withoutCity))}
                  className="p-2 flex flex-col cursor-pointer rounded-xl hover:text-blue-700"
                >
                  <span>{ getAddressString(result, streetOnly, withoutCity) }</span>
                  {/*<span className="text-sm text-gray-400">{ result.value?.city }</span>*/}
                </div>
              )
            })
          }
        </div>
      </Transition>
    </div>
  )
}
