import { ClockIcon, GiftIcon, MapPinIcon, UserIcon } from '@heroicons/react/20/solid';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { CartAddress, CartMobileBottomBar, CartMobileDrawerButton, PaymentMethodSelectDesktop } from 'components/cart';
import { BlockDivider, Button, Emoji } from 'components/common';
import { MobileCartContext } from 'context';
import { Form, Formik } from 'formik';
import { useCartData } from 'hooks';
import { useCart } from 'query/orders';
import { useContext, useState } from 'react';
import { formatShippingDate, getFormattedPhoneNumber } from 'utils';

export const CartCheckoutScreen = () => {
  const { setCurrentScreen } = useContext(MobileCartContext);
  const { data, isLoading: isCartLoading } = useCart();
  const [ localCartData, setLocalCartData, setLocalCartDataKey ] = useCartData();


  const [ isDateModalOpen, setDateModalOpen ] = useState(false);
  const [ isAddressModalOpen, setAddressModalOpen ] = useState(false);
  const [ isContactsModalOpen, setContactsModalOpen ] = useState(false);
  const [ isReceiverModalOpen, setReceiverModalOpen ] = useState(false);
  const { title: addressTitle, subtitle: addressSubtitle } = CartAddress(data);

  return (
    <div>
      <div onClick={() => setCurrentScreen('details')}>назад</div>
      <div className="flex flex-col gap-3 px-4 mt-2">
        <CartMobileDrawerButton
          icon={<MapPinIcon className="w-6 h-6"/>}
          title={addressTitle}
          problems={!data.address?.street}
          subtitle={addressSubtitle}
          onClick={() => setAddressModalOpen(true)}
        />
        <CartMobileDrawerButton
          icon={<ClockIcon className="w-6 h-6"/>}
          problems={!data.selectedShippingDate}
          title={
            data.selectedShippingDate
              ? formatShippingDate(data.selectedShippingDate)
              : 'Дата доставки'
          }
          // problems={!data.selectedShippingDate}
          onClick={() => setDateModalOpen(true)}
        />
        <div className="mt-2 border-t border-gray-100"/>
        <CartMobileDrawerButton
          icon={<UserIcon className="w-6 h-6"/>}
          problems={!data.createdBy?.fullName || !data.createdBy?.phoneNumber}
          title={
            data.createdBy?.fullName
              ? `Заказывает ${data.createdBy.fullName}`
              : 'Ваши контакты'
          }
          subtitle={
            data.createdBy?.phoneNumber
              ? getFormattedPhoneNumber(data.createdBy?.phoneNumber)
              : (data.createdBy?.fullName && <span className="text-orange-600">Уточните свой номер телефона</span>)
          }
          onClick={() => setContactsModalOpen(true)}
        />
        {
          !localCartData.isNotGift &&
          <CartMobileDrawerButton
            icon={<GiftIcon className="w-6 h-6"/>}
            title={data.receiver?.name ? `Получит ${data.receiver?.name}` : 'Контакты получателя'}
            subtitle={
              (!data.receiver?.name && !data.receiver?.phoneNumber)
                ? null
                : (data.receiver?.name && !data.receiver?.phoneNumber)
                  ? <>Уточните номер телефона <Emoji size={3}>1f64f</Emoji></>
                  : getFormattedPhoneNumber(data.receiver?.phoneNumber)
            }
            onClick={() => setReceiverModalOpen(true)}
          />
        }
      </div>
      <BlockDivider/>
      <div className="px-6">
        <h3>способ оплаты</h3>
        <Formik initialValues={{ paymentMethod: 'sbp' }} onSubmit={() => {}}>
          <Form>
            <PaymentMethodSelectDesktop
              name="paymentMethod"
              value={localCartData.paymentMethod}
            />
          </Form>
        </Formik>

      </div>
      <div className="h-20"/>
      <CartMobileBottomBar>
        <div className="p-4 flex items-center gap-4">
          <Button color="transparent" className="-ml-3 flex gap-1 font-medium">
            <ChevronLeftIcon className="w-5 h-5"/>
            Назад
          </Button>
          <Button className="flex-1" color="blue">
            привт
          </Button>
        </div>
      </CartMobileBottomBar>
    </div>
  )
}
