import { DolyameSchedule } from 'components/dolyame';

export function DolyameModal({ productId, price, onClose }) {
  return (
    <div className="text-sm lg:text-base p-6 gap-6 flex flex-col">
      <div className="flex gap-3 flex-col">
        <h1 className="text-xl md:text-3xl mb-1 font-bold">Оплатите 25% от стоимости</h1>
        <p>Оставшиеся 3 части спишутся автоматически с шагом в две недели</p>
        <p>При оформлении заказа выберите способ оплаты «Долями»</p>
      </div>
      <div className="grid md:grid-cols-2 gap-2 flex-row">
        <div className="flex items-center gap-3">
          <img className="w-10 h-10" src="/dolyame/star.svg"/>
          <p>Без процентов и комиссий</p>
        </div>
        <div className="flex items-center gap-3">
          <img className="w-10 h-10" src="/dolyame/card.svg"/>
          <p>Как обычная оплата картой</p>
        </div>
      </div>
      <DolyameSchedule price={price}/>
      {/*<button className="flex gap-3 justify-center items-center rounded-2xl bg-blue-700 text-white p-4">*/}
      {/*  Оплатить*/}
      {/*  <img className="h-4" src="/dolyame/logo.svg"/>*/}
      {/*</button>*/}
      <p className="-mt-1 text-sm text-center">
        Подробнее о сервисе можно узнать <a className="text-blue-700" href={'https://dolyame.ru'} target="_blank" rel="noreferrer">на сайте</a>
      </p>
    </div>
  )
}

