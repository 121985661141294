export const getAddressString = (result, streetOnly = false, withoutCity = false) => {
  if (!result) return '';

  const { city, street, streetType, houseNumber, houseType, apartmentType, apartment } = result;
  const parts = [];

  if (!withoutCity) {
    parts.push(city);
  }

  if (street) {
    parts.push(`${streetType || 'улица'} ${street}`);

    if (!streetOnly && houseNumber) {
      parts.push(`${houseType || 'дом'} ${houseNumber}`);

      if (apartment) {
        parts.push(`${apartmentType || 'квартира'} ${apartment}`);
      }
    }
  }

  return parts.join(', ');
}

export const getShortAddress = (result) => {
  if (!result) return '';

  const parts = [];

  if (result.street) {
    parts.push(result.street);

    if (result.house) {
      parts.push(result.house);
    }
  }

  return parts.join(', ');
}
