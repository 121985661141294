import { ChatBubbleOvalLeftIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import { HoverPopover } from 'components/common';
import { CallModal } from 'components/modals';
import { ChatPopoverContent } from 'components/popovers/ChatPopover';
import { useChatMessagesCount } from 'hooks';
import { forwardRef, useEffect, useState } from 'react';

import popoverStyles from './ChatPopover.module.css';


const ChatPopoverButton = forwardRef(({ open = true, chatMessagesCount, ...props }, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      className={cn(!open && 'text-opacity-90', popoverStyles.btn)}
    >
      <ChatBubbleOvalLeftIcon className="h-7 w-7 md:h-9 md:w-9"/>
      {
        (!open && chatMessagesCount > 0) &&
        <div className="absolute -top-1 -right-0.5">
          <CountBadge>{ chatMessagesCount }</CountBadge>
        </div>
      }
    </div>
  )
});

ChatPopoverButton.displayName = 'ChatPopoverButton';

export function ChatPopover() {
  const [ isCallModalOpen, setCallModalOpen ] = useState(false);
  const [ chatMessagesCount, setChatMessagesCount ] = useChatMessagesCount();


  useEffect(() => {
    if (typeof window !== 'undefined' && window.ChatraSetup) {
      window.ChatraSetup.onNewMessage = function () {
        if (!window.Chatra?._chatExpanded) {
          setChatMessagesCount(chatMessagesCount + 1);
        }
      }
    }
  }, [ chatMessagesCount, setChatMessagesCount ]);

  return (
    <>
      <CallModal isOpen={isCallModalOpen} setIsOpen={setCallModalOpen}/>
      <HoverPopover
        offsetLeft={12}
        placement="top-end"
        offsetTop={-8}
        target={<ChatPopoverButton/>}
      >
        <nav className="p-4 mt-3 bg-white rounded-2xl shadow-2xl">
          <ChatPopoverContent onCallbackRequested={() => setCallModalOpen(true)}/>
        </nav>
      </HoverPopover>
    </>
  )
}

function CountBadge({ children }) {
  return (
    <div
      style={ { minWidth: '20px' } }
      className="h-5 text-white text-xs flex justify-center items-center rounded-3xl bg-red-500"
    >
      { children }
    </div>
  )
}
