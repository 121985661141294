import cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';

import APIService from './api.service';


export class OrdersService extends APIService {
  constructor(params) {
    super(params);
  }

  async createCart(slug) {
    return this.api.post('/orders');
  }

  async getPaymentStatus(id) {
    return this.api.get(`/orders/${id}/paymentStatus`);
  }

  async getOrderStatuses(ids) {
    if (!ids.length) {
      return [];
    }

    return this.api.get(`/orders/status/${ids}`);
  }

  async getById(id) {
    return this.api.get(`/orders/${id}`);
  }

  async getCart(uuid) {
    return this.api.get(`/orders/${uuid}`);
  }

  async update(cartId, params) {
    const cookieCartId = cookies.get('flopus_cart');

    if (cartId || cookieCartId) {
      return this.api.post(`/orders/${cartId}`, params);
    }

    return false;
  }

  async editCartItem(itemUUID, qty) {
    const cartId = cookies.get('flopus_cart') || uuid();

    cookies.set('flopus_cart', cartId, {
      domain: process.env.NEXT_PUBLIC_DOMAIN,
      expires: 365,
      initializeWithValue: true
    });

    return this.api.post(`/orders/${cartId}/products/${itemUUID}`, {
      qty
    });
  }

  async addToCart(productId, qty) {
    const cartId = cookies.get('flopus_cart') || uuid();

    cookies.set('flopus_cart', cartId, {
      domain: process.env.NEXT_PUBLIC_DOMAIN,
      expires: 365,
      initializeWithValue: true
    });

    return this.api.post(`/orders/${cartId}/products`, {
      productId,
      qty
    });
  }

  async updatePostCard(orderId, text) {
    if (!text?.length) {
      return this.api.delete(`/orders/${orderId}/metadata/postcard_text`);
    }

    return this.api.post(`/orders/${orderId}/metadata`, {
      key: 'postcard_text',
      title: 'Текст открытки',
      value: text
    });
  }
}
