import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

export function CloseModalButton({ className, iconClassName, ...props }) {
  return (
    <button
      {...props}
      className="absolute top-4 right-4 bg-white shadow border border-gray-100 p-2 m-2 rounded-full hover:bg-gray-50 cursor-pointer outline-none"
    >
      <XMarkIcon className={classNames('text-black/80 w-6 h-6', iconClassName)}/>
    </button>
  )
}
