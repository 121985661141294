import cn from 'classnames';

export const ToastWithLink = ({ className, visible, onClick, actionTitle, text }) => {
  return (
    <div className="w-full backdrop-blur-lg rounded-2xl overflow-hidden">
      <div
        className={cn(`${visible ? 'animate-enter' : 'animate-leave'} text-sm gap-4 px-6 py-4 items-center max-w-md w-full bg-gray-900 bg-opacity-70 pointer-events-auto flex`, className)}
      >
        <div className="text-white w-full">
          { text }
        </div>
        <button
          onClick={onClick}
          className="h-full flex text-blue-400 whitespace-nowrap font-semibold"
        >
          { actionTitle }
        </button>
      </div>
    </div>
  )
}
