import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import cn from 'classnames';
import { HoverPopover } from 'components/common';
import { Drawer } from 'components/modals';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { forwardRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import styles from './SortingPopover.module.css';


const PopoverContent = ({ selectedKey, values, onChange }) => {
  const router = useRouter();

  const handleSortingChange = (key) => {
    if (selectedKey === key) {
      return;
    }

    if (onChange) {
      onChange(key);
    }

    delete router.query.page;
    
    if (key === 'default') {
      delete router.query.order;

      return router.push({
        query: router.query
      });
    } else {
      return router.push({
        query: {
          ... router.query,
          order: key
        }
      });
    }
  }

  return (
    <div className={styles.popover}>
      {
        values.map(({ key, title }) => {
          const isActive = key === selectedKey;

          return (
            <div
              key={ key }
              className={ cn(styles.item, isActive && 'font-medium text-blue-600') }
              onClick={ () => handleSortingChange(key) }
            >
              { key === selectedKey ? <CheckIcon className="w-5 h-5"/> : <div className="w-5"/> }
              { title }
            </div>
          );
        })
      }
    </div>
  )
};

export function SortingPopover({ values: propValues, defaultTitle }) {
  const [ isDrawerVisible, setDrawerVisible ] = useState(false);
  const { width = 1024 } = useWindowSize();
  const router = useRouter();

  const currentSortingRule = router.query.order || 'default';
  const values = [
    {
      key: 'default',
      title: defaultTitle || 'по популярности'
    }
  ];

  if (propValues && Array.isArray(propValues)) {
    values.push(...propValues);
  } else {
    values.push(...[
      { key: 'aprice', title: 'сначала дешевле', selectedTitle: 'сначала недорогие' },
      { key: 'dprice', title: 'сначала дорогие', selectedTitle: 'сначала дорогие' },
      { key: 'discount', title: 'со скидкой' }
    ]);
  }

  const SortingButton = forwardRef((props, ref) => {
    const defaultValue = values.find(({ key }) => key === currentSortingRule);

    return (
      <div ref={ref} {...props} className={styles.selected}>
        { defaultValue.selectedTitle || defaultValue.title }
        <ChevronDownIcon className="w-5 h-5"/>
      </div>
    )
  });

  SortingButton.displayName = 'SortingPopoverTargetButton';

  if (width < 768) {
    return (
      <>
        <SortingButton onClick={() => setDrawerVisible(true)}/>
        <Drawer
          visible={isDrawerVisible}
          onClose={() => setDrawerVisible(false)}
        >
          <div className="mt-4">
            <PopoverContent
              values={values}
              selectedKey={currentSortingRule}
              onChange={() => setDrawerVisible(false)}
            />
          </div>
        </Drawer>
      </>
    );
  }

  return (
    <HoverPopover
      placement="bottom-end"
      offsetTop={8}
      offsetLeft={2}
      target={<SortingButton/>}
    >
      <PopoverContent values={values} selectedKey={currentSortingRule}/>
    </HoverPopover>
  )
}

SortingPopover.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  defaultTitle: PropTypes.string
};
