import dayjs from 'dayjs';

export function formatNumber(value) {
  return (value | 0).toLocaleString('ru');
  // return value > 9999 ? (value | 0).toLocaleString('ru') : (value | 0);
}

const months = [
  'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
  'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
];

const weekDay = [ 'В воскресенье', 'В понедельник', 'Во вторник', 'В среду', 'В четверг', 'В пятницу', 'В субботу' ];


export const formatDuration = (value) => {
  if (value > 60) {
    return getNoun(Math.round(value / 2), 'час', 'часа', 'часов') +
      ' ' +
      formatDuration(value % 60)
  }

  return getNoun(value, 'минуту', 'минуты', 'минут');
}

export const formatDate = (value) => {
  if (!value) {
    return;
  }

  return `${new Date(value).getDate()} ${months[new Date(value).getMonth()]}`;
}

export const replaceTemplateVariables = (input, variables) => {
  if (!input) {
    return input;
  }

  return Object.entries(variables)
    .reduce((acc, [ key, value ]) => (
      acc.replace(new RegExp(`\\{${key}}`, 'ig'), value)
    ), input.toString())
    .replace(/\{\w+}/ig, '')
    .replace(/\s+/, ' ')
    .trim();
}

export const formatShippingDate = (value) => {
  if (!value) {
    return;
  }

  const dayjsDate = dayjs(value);
  const parts = [
    // `${formatDate(value)} к ${dayjsDate.format('H:mm')}`
    formatDate(value)
  ];

  if (dayjsDate.isSame(new Date(), 'day')) {
    parts.unshift('Сегодня');
  } else if (dayjsDate.isSame(dayjs().add(1, 'day'), 'day')) {
    parts.unshift('Завтра');
  } else {
    parts.unshift(weekDay[dayjsDate.day()])
  }

  return parts.join(', ');
}

export function getRawPhoneNumber(value) {
  return value.replace(/[^+0-9]/g, '');
}

export function formatPrice(price) {
  const [ value ] = (price / 100).toFixed(2).split('.');

  return formatNumber(value).toString().replace(/-/, '− ');
}

export function getFormattedPhoneNumber(value) {
  const rawPhone = getRawPhoneNumber(value);
  const parts = rawPhone.match(/^(\+?7|8)?(\d{3})(\d{3})(\d{4})/);

  if (!parts?.length) {
    return value;
  }

  if (parts[1] === '8' || parts[1] === '7') {
    parts[1] = '+7';
  }

  return parts.slice(1).join(' ');
}

export function getNoun(number, one, two, five) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
}
