import { HostContext } from 'context';
import { useContext } from 'react';
import { createProductLinkedData } from 'utils';

export function ProductLinkedData({ product, ... props }) {
  const hostname = useContext(HostContext);

  if (!product) {
    return null;
  }

  return (
    <script
      { ...props }
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          createProductLinkedData(hostname, product)
        )
      }}
    />
  )
}
