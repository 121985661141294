import { MapPinIcon } from '@heroicons/react/24/solid';
import cn from 'classnames';
import { ModalWrapper } from 'components/modals';
import { MODALS } from 'components/modals/constants';
import { CitiesContext } from 'context';
import { useContext } from 'react';
import { reachGoal } from 'utils/metrika';


export const IDENTIFIER = MODALS.CITY;

export function CityModal({ isOpen, setIsOpen }) {
  const { currentCity, cities } = useContext(CitiesContext);

  return (
    <ModalWrapper
      sheetClass="bg-header"
      modalStyle={{ maxWidth: '620px' }}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onDismiss={() => setIsOpen(false)}
    >
      <div className="p-3 lg:p-4">
        <h3 className="pt-3 px-3 text-xl font-bold leading-6 text-gray-900">
          Где Вы находитесь?
        </h3>
        <div className="space-y-2 pt-3">
          {
            cities.map(city => {
              const isCurrent = currentCity.slug === city.slug;
              const hostname = [];

              if (city.slug) {
                hostname.push(city.slug);
              }

              hostname.push(process.env.NEXT_PUBLIC_DOMAIN);

              return (
                <div
                  key={city.slug}
                  onClick={() => {
                    if (isCurrent) {
                      reachGoal('city_changed', {
                        city: city.title
                      });

                      window.location = `https://${hostname.join('.')}${window.location.pathname}`;
                    }
                  }}
                  className={
                    cn(
                      'px-3 py-3 flex gap-2  items-center cursor-pointer hover:bg-gray-100 rounded-xl',
                      (isCurrent) && 'font-semibold text-blue-700'
                    )
                  }
                >
                  { city.title }
                  { (isCurrent) && <MapPinIcon className="w-5 h-5 text-blue-600"/> }
                </div>
              )
            })
          }
        </div>
      </div>
    </ModalWrapper>
  )
}
