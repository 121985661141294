import { BellAlertIcon, ChatBubbleLeftRightIcon, PhoneIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import { ChatPopoverCountBadge } from 'components/popovers';
import { useChatMessagesCount } from 'hooks';
import { useEffect, useState } from 'react';
import Loading from 'react-loading';
import { sendContactClickEvent } from 'services/api/utils.service';
import { reachGoal } from 'utils/metrika';


function MenuItem({ className, children, ...props }) {
  return (
    <div
      {...props}
      className={
        cn(className, 'flex gap-3 items-center cursor-pointer p-3 hover:bg-gray-50 rounded-xl pr-10')
      }
    >
      { children }
    </div>
  )
}

function getWhatsAppMessage() {
  if (typeof window !== 'undefined') {
    try {
      if (window.location.pathname.match(/^\/.*\/.*$/)) {
        const schemas = document.querySelectorAll('script[type="application/ld+json"]');

        for (const schemaContainer of schemas) {
          const schema = JSON.parse(schemaContainer.innerHTML);

          if (schema['@type'] === 'Product') {
            return encodeURI(`Здравствуйте!\nМеня интересует «${ schema.name }» (${ window.location.href.replace(/https?:\/\//, '') })\n\n`)
          }
        }

        return encodeURI(`Здравствуйте!\nМеня интересует этот товар: ${window.location.href.replace(/https?:\/\//, '')})\n\n`)
      }

      if (window.ChatraSetup?.clientId) {
        if (window.location.pathname.match(/^\/cart/)) {
          return encodeURI(`Здравствуйте, у меня есть вопрос по заказу ${window.ChatraSetup.clientId}\n\n`);
        }
      }
    } catch (e) {}
  }

  return 'Здравствуйте, '
}

export function ChatPopoverContent({ onCallbackRequested }) {
  const [ chatMessagesCount, setChatMessagesCount ] = useChatMessagesCount();
  const [ isChatraLoaded, setChatraLoaded ] = useState(false);

  const chatLoadedEvent = (e) => {
    if (e.data === 'flopus_livechat_loaded') {
      setChatraLoaded(true);
      console.log('live chat initialized');
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('message', chatLoadedEvent);

      return () => window.removeEventListener('message', chatLoadedEvent);
    }
  }, []);

  const openChat = () => {
    if (!window.Chatra) return;

    Chatra('openChat', true);

    setChatMessagesCount(0);
  }

  setTimeout(() => setChatraLoaded(true), 3000);

  return (
    <div className="flex flex-col gap-3">
      <div>
        <a
          href={`viber://chat?number=${process.env.NEXT_PUBLIC_PHONE_NUMBER.replace(/^8/, '7')}&text=${getWhatsAppMessage()}`}
          onClick={() => {
            sendContactClickEvent('vb', 'modal');
            reachGoal('contact_click', { type: 'viber' });
          }}
        >
          <MenuItem className="relative hover:bg-violet-50 text-violet-700">
            <img className="absolute left-2 top-2.5 w-8 h-8" src={'/icons/viber.svg'} alt=""/>
            <span className="ml-9">Написать в Viber</span>
          </MenuItem>
        </a>
        <a
          href={`https://wa.me/${process.env.NEXT_PUBLIC_PHONE_NUMBER.replace(/^8/, '7')}?text=${getWhatsAppMessage()}`}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            sendContactClickEvent('wa', 'modal');
            reachGoal('contact_click', { type: 'whatsapp' });
          }}
        >
          <MenuItem className="relative hover:bg-green-50 text-green-700">
            <img className="absolute top-1.5 left-1.5 w-9 h-9 rounded-max" src={'/icons/whatsapp.svg'} alt=""/>
            <span className="ml-9">Написать в WhatsApp</span>
          </MenuItem>
        </a>
        <a
          href="https://t.me/flopus_flowers"
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            sendContactClickEvent('tg', 'modal');
            reachGoal('contact_click', { type: 'telegram' });
          }}
        >
          <MenuItem className="hover:bg-blue-50 text-blue-700">
            <img className="w-6 h-6" src={'/icons/telegram.svg'} alt=""/>
            Написать в Telegram
          </MenuItem>
        </a>
      </div>
      <div>
        <a
          href={`tel:${process.env.NEXT_PUBLIC_PHONE_NUMBER}`}
          onClick={() => {
            sendContactClickEvent('ph', 'modal');
            reachGoal('contact_click', { type: 'phone' });
          }}
        >
          <MenuItem>
            <PhoneIcon className="w-6 h-6"/>
            { process.env.NEXT_PUBLIC_FORMATTED_PHONE_NUMBER }
          </MenuItem>
        </a>
        <MenuItem onClick={() => onCallbackRequested && onCallbackRequested()}>
          <BellAlertIcon className="w-6 h-6"/>
          Перезвоните мне
        </MenuItem>
        <MenuItem
          onClick={() => openChat()}
          { ... (!isChatraLoaded) && {
            className: 'opacity-50',
          }}
        >
          {
            isChatraLoaded
              ? <ChatBubbleLeftRightIcon className="w-6 h-6"/>
              : <Loading height={24} width={24} color="black" type="spin"/>
          }
          Онлайн чат
          {
            chatMessagesCount > 0 && <ChatPopoverCountBadge>{ chatMessagesCount }</ChatPopoverCountBadge>
          }
        </MenuItem>
      </div>
    </div>
  )
}
