import APIService from './api.service';


export class ProductsService extends APIService {
  constructor(params) {
    super(params);
  }

  async getCategory(id) {
    return this.api.get(`/categories/${id}`);
  }

  async getCatalog(shopId = 1) {
    return this.api.get('/products/catalog', {
      params: {
        shopId
      }
    });
  }

  async getMany(params) {
    return this.api.get('/products', {
      params
    });
  }

  async getPromotedTags() {
    const items = await this.api.get('/products/tags');

    return items
      .filter(({ productsCount }) => productsCount > 0)
      .filter(({ metadata }) => {
        const isFeatured = metadata.find(({ key }) => key === 'isFeatured')?.value;
        const specialAvailability = metadata.find(({ key }) => key === 'availability')?.value
          ?.split(',')
          ?.map(i => +i);

        if (!isFeatured) {
          return false;
        }

        if (specialAvailability?.length) {
          return specialAvailability.includes(new Date().getMonth() + 1);
        }

        return true;
      })
      .reduce((acc, { id, seoTitle, title, slug, metadata }) => {
        const item = { id, seoTitle, title, slug };

        metadata.forEach(({ key, value }) => item[key] = value);
        acc.push(item);

        return acc;
      }, [])
      .sort((a, b) => Math.max(a.featuringPriority, 1) - Math.max(b.featuringPriority, 1));
  }

  async getTaggedProducts(tagIds = [], count = 100) {
    return this.api.get(`/products?tagIds=${tagIds.join(',')}&count=${count}`);
  }

  async getBySlug(slug) {
    return this.api.get(`/products/${slug}`);
  }

  async getById(id) {
    return this.api.get(`/products/${id}`);
  }

  async getByIds(ids) {
    return this.api.get('/products', {
      params: {
        productIds: ids
      }
    });
  }

  async getCategories(minCount = 1) {
    return this.api.get('/categories')
      .then(response => ({
        ... response,
        items: response?.items.filter(({ productsCount }) => productsCount >= minCount) || []
      }));
  }

  async getByTag(tagId) {
    return this.api.get('/products', {
      params: {
        tagIds: tagId,
        count: 200
      }
    });
  }

  async getByCategorySlug(categorySlug) {
    return this.api.get('/products', {
      params: {
        categorySlug,
        count: 200
      }
    });
  }

  async getByCategory(categoryId) {
    return this.api.get('/products', {
      params: {
        categoryId
      }
    });
  }
}
