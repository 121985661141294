import Link from 'next/link';

export function Logo({ className, size = 69, href = '/' }) {
  return (
    <Link aria-label="Главная" prefetch={false} href={href}>
      <img
        alt="флопус"
        className={className}
        height={size}
        width={size}
        src={'/logo-bg.svg'}
      />
    </Link>

  )
}
