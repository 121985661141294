import { ClockIcon, GiftIcon, MapPinIcon, UserIcon } from '@heroicons/react/20/solid';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { CartProductList } from 'components/cart';
import { Button, Emoji, Price } from 'components/common';
import {
  DeliveryAddressModal,
  DeliveryContactsModal,
  DeliveryDateModal,
  DeliveryReceiverModal,
  Drawer,
  PaymentMethodMobileModal,
  PostCardModal
} from 'components/modals';
import { MODALS } from 'components/modals/constants';
import { CartPopoverEmptyGap } from 'components/popovers/CartPopover/CartPopoverEmptyGap';
import { useCartData } from 'hooks';
import { useRouter } from 'next/router';
import { useCart, useSubmitOrder } from 'query/orders';
import { useState } from 'react';
import { formatShippingDate, getFormattedPhoneNumber } from 'utils';
import { ENABLED_PAYMENT_METHODS } from 'utils/constants';
import { reachGoal } from 'utils/metrika';

import { Address, DeliveryOption, OpenNestedDrawerButton } from './components';


export const IDENTIFIER = MODALS.CART_MOBILE;

export function CartMobileModal({ visible, onClose }) {
  const { data, isLoading: isCartLoading } = useCart();
  const router = useRouter();
  const [ isDateModalOpen, setDateModalOpen ] = useState(false);
  const [ isAddressModalOpen, setAddressModalOpen ] = useState(false);
  const [ isContactsModalOpen, setContactsModalOpen ] = useState(false);
  const [ isReceiverModalOpen, setReceiverModalOpen ] = useState(false);
  const [ isPostCardModalOpen, setPostCardModalOpen ] = useState(false);
  const [ isPaymentMethodModalOpen, setPaymentMethodModalOpen ] = useState(false);
  const [ paymentMethod, setPaymentMethod ] = useState(ENABLED_PAYMENT_METHODS[0]);
  const { isLoading: isSubmitLoading, mutateAsync: submitOrder } = useSubmitOrder();
  const [ cartData, setCartData, setCartKey ] = useCartData();

  const { title: addressTitle, subtitle: addressSubtitle } = Address(data);


  const isDragDisabled = isAddressModalOpen || isPostCardModalOpen ||
    isPaymentMethodModalOpen || isReceiverModalOpen ||
    isDateModalOpen || isContactsModalOpen;

  if (!data?.items?.length) {
    return (
      <Drawer
        onClose={() => onClose()}
        visible={visible}
        titleComponent={true}
      >
        <div className="my-16">
          <CartPopoverEmptyGap/>
        </div>
      </Drawer>
    );
  }

  const isPaymentButtonActive = data.selectedShippingDate
    && data.createdBy?.phoneNumber;

  const postCardText = data.metadata.find(({ key }) => key === 'postcard_text')?.value;

  return (
    <>
      <Drawer
        onClose={() => onClose()}
        visible={visible}
        dragDisabled={isDragDisabled}
        titleComponent={true}
        maxSize="calc(100% - 66px)"
      >
        <div className="bg-gray-100 flex flex-col gap-2">
          <div className="p-4 bg-white rounded-xl flex flex-col gap-4">
            <div className="flex flex-col gap-3 mt-2">
              <OpenNestedDrawerButton
                icon={<MapPinIcon className="w-6 h-6"/>}
                title={addressTitle}
                problems={!data.address?.street}
                subtitle={addressSubtitle}
                onClick={() => setAddressModalOpen(true)}
              />
              <OpenNestedDrawerButton
                icon={<ClockIcon className="w-6 h-6"/>}
                problems={!data.selectedShippingDate}
                title={
                  data.selectedShippingDate
                    ? formatShippingDate(data.selectedShippingDate)
                    : 'Дата доставки'
                }
                // problems={!data.selectedShippingDate}
                onClick={() => setDateModalOpen(true)}
              />
              <div className="mt-2 border-t border-gray-100"/>
              <OpenNestedDrawerButton
                icon={<UserIcon className="w-6 h-6"/>}
                problems={!data.createdBy?.fullName || !data.createdBy?.phoneNumber}
                title={
                  data.createdBy?.fullName
                    ? `Заказывает ${data.createdBy.fullName}`
                    : 'Ваши контакты'
                }
                subtitle={
                  data.createdBy?.phoneNumber
                    ? getFormattedPhoneNumber(data.createdBy?.phoneNumber)
                    : (data.createdBy?.fullName && <span className="text-orange-600">Уточните свой номер телефона</span>)
                }
                onClick={() => setContactsModalOpen(true)}
              />
              {
                !cartData.isNotGift &&
                <OpenNestedDrawerButton
                  icon={<GiftIcon className="w-6 h-6"/>}
                  title={data.receiver?.name ? `Получит ${data.receiver?.name}` : 'Контакты получателя'}
                  subtitle={
                    (!data.receiver?.name && !data.receiver?.phoneNumber)
                      ? null
                      : (data.receiver?.name && !data.receiver?.phoneNumber)
                        ? <>Уточните номер телефона <Emoji size={3}>1f64f</Emoji></>
                        : getFormattedPhoneNumber(data.receiver?.phoneNumber)
                  }
                  onClick={() => setReceiverModalOpen(true)}
                />
              }
            </div>
            <div className="flex gap-2 transition-all">
              <DeliveryOption active={!cartData.isNotGift} onClick={() => setCartKey('isNotGift', false)}>
                <img src={'/icons/gift.png'} width="22" height="22" alt=""/>
                <div className="mr-6 mt-2 text-sm font-medium">Доставить<br/>кому-то</div>
              </DeliveryOption>
              <DeliveryOption active={cartData.isNotGift} onClick={() => setCartKey('isNotGift', true)}>
                <img src={'/icons/delivery.png'} width="22" height="22" alt=""/>
                <div className="mr-6 mt-2 text-sm font-medium">Доставить<br/>мне</div>
              </DeliveryOption>
            </div>
          </div>
          <div
            onClick={() => setPostCardModalOpen(true)}
            className="bg-white rounded-2xl cursor-pointer rounded-xl px-4 py-3 flex shrink-0 items-center justify-between gap-3"
          >
            <div className="w-12 block shrink-1 flex justify-center">
              <img src={'/postcard.png'} className="h-10" alt=""/>
            </div>
            {
              postCardText &&
              <div className="flex-1 justify-between font-medium text-sm truncate">
                В открытке напишем <br/><span className="text-gray-500">«{ postCardText }»</span>
              </div>
            }
            {
              !postCardText &&
              <div className="flex-1 justify-between  font-medium truncate">
                { cartData?.postCardText?.length ? cartData.postCardText : <>Бесплатно добавить открытку</> }
              </div>
            }
            <div className="ml-2">
              <ChevronRightIcon className="h-5 w-5 opacity-40 group-hover:opacity-70"/>
            </div>
          </div>

          <div className="p-4 bg-white rounded-2xl space-y-2.5">
            <CartProductList previewClassName="w-12 h-12"/>
          </div>

          <div className="h-16 mb-3"/>
          <div
            className="fixed border-t border-gray-100 bottom-0 w-full bg-white rounded-t-2xl flex flex-col"
            // style={{ boxShadow: '0 -8px 6px -6px rgba(0, 0, 0, 0.05)' }}
            style={{ paddingBottom: 'env(safe-area-inset-bottom)' }}
          >
            {
              !isPaymentButtonActive && <span className="font-medium text-orange-600 px-2 text-center mt-2 -mb-2 text-sm">Для оплаты нужно указать контакты и время доставки</span> 
            }
            <div className="relative px-3 py-3 gap-2 items-center flex rounded-xl">
              <div
                className="flex items-center hover:bg-gray-100 p-2 rounded-xl"
                onClick={() => setPaymentMethodModalOpen(true)}
              >
                <ChevronDownIcon className="w-4 h-4"/>
                {/*{ paymentMethod.icon }*/}
                <div className="text-xs mt-1 ml-2" style={{ maxWidth: '64px' }}>{ paymentMethod.shortName }</div>
              </div>
              <Button
                onClick={async () => {
                  const result = await submitOrder({
                    uuid: data.uuid,
                    paymentMethod: paymentMethod.id,
                    selectedShippingDate: data.selectedShippingDate,
                  });

                  if (result?.data?.id) {
                    console.log('>>>>>>>>>', data)
                    reachGoal('submit_order', {
                      order_price: data.price.subtotal / 100,
                      currency: 'RUB'
                    });

                    router.replace(`/orders/${result.data.id}`);
                  }

                  onClose();
                }}
                size="lg"
                color="blue"
                className="flex-1 gap-2 font-medium"
                disabled={!isPaymentButtonActive || isSubmitLoading || isCartLoading}
              >
                Оплатить <Price className="font-medium" price={data?.price?.total}/>
              </Button>
            </div>
          </div>
        </div>

        <Drawer
          nested={true}
          visible={isPostCardModalOpen}
          onClose={() => setPostCardModalOpen(false)}
          titleComponent={true}
        >
          <PostCardModal setIsOpen={setPostCardModalOpen} />
        </Drawer>


        <Drawer
          nested={true}
          onClose={() => setAddressModalOpen(false)}
          visible={isAddressModalOpen}
          titleComponent={true}
        >
          <div className="p-4 pt-6">
            <DeliveryAddressModal onChange={() => setAddressModalOpen(false)}/>
          </div>
        </Drawer>

        <Drawer
          nested={true}
          onClose={() => setContactsModalOpen(false)}
          visible={isContactsModalOpen}
          titleComponent={true}
        >
          <div className="p-4 pt-6">
            <DeliveryContactsModal onChange={() => setContactsModalOpen(false)}/>
          </div>
        </Drawer>

        <Drawer
          nested={true}
          onClose={() => setDateModalOpen(false)}
          visible={isDateModalOpen}
          dragDisabled={true}
          titleComponent={true}
        >
          <div className="p-4 pt-6">
            <DeliveryDateModal onChange={() => setDateModalOpen(false)}/>
          </div>
        </Drawer>

        <Drawer
          onClose={() => setReceiverModalOpen(false)}
          visible={isReceiverModalOpen}
          titleComponent={true}
          nested={true}
        >
          <div className="p-4 pt-6">
            <DeliveryReceiverModal onChange={() => setReceiverModalOpen(false)}/>
          </div>
        </Drawer>

        {/* payment method modal */}
        <Drawer
          onClose={() => setPaymentMethodModalOpen(false)}
          visible={isPaymentMethodModalOpen}
          titleComponent={true}
          hideCloseButton={true}
          nested={true}
        >
          <PaymentMethodMobileModal
            current={paymentMethod}
            onSelect={(method) => {
              setPaymentMethod(method);
              setPaymentMethodModalOpen(false)
            }}
          />
        </Drawer>
      </Drawer>
    </>
  )
}
