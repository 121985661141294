import { useCookieValue } from '@react-hookz/web/useCookieValue/index.js';
import { useMemo } from 'react';
import { ENABLED_PAYMENT_METHODS } from 'utils/constants';

export function useCartData() {
  const paymentMethod = useMemo(() => ENABLED_PAYMENT_METHODS[0].id, []);
  const defaultState = {
    senderName: '',
    senderPhoneNumber: '',
    receiverName: '',
    receiverPhoneNumber: '',
    paymentMethod: paymentMethod.id
  };

  const [ value, set ] = useCookieValue('flopus_cart_data', {
    domain: process.env.NEXT_PUBLIC_DOMAIN,
    expires: 365,
    initializeWithValue: true
  });

  const initialState = typeof value === 'string' ? JSON.parse(value) : defaultState;

  return [
    initialState,
    (value) => set(JSON.stringify(value)),
    (key, value) => set(JSON.stringify({
      ... initialState,
      [key]: value
    }))
  ];
}
