import { Dialog } from '@headlessui/react';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import cn from 'classnames';
import { Price, ToastWithLink } from 'components/common';
import { DolyameLargeProductPrice } from 'components/dolyame';
import { CartMobileModal } from 'components/modals/CartMobileModal/CartMobileModal';
import { MODALS } from 'components/modals/constants';
import { ProductModalContent } from 'components/modals/ProductModal/components/ProductModalContent';
import { ProductCartActionButton } from 'components/products';
import { useRouter } from 'next/router';
import { useCart } from 'query/orders';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Loading from 'react-loading';
import { useLocation } from 'react-use';
import { useProductModal } from 'store';
import { isEligibleForDolyame } from 'utils';


export const IDENTIFIER = MODALS.PRODUCT;

const IconButton = ({ icon, children, className }) => {
  return (
    <div className="w-12 h-12 bg-white rounded-full shadow-md hover:bg-gray-50 p-3">
      { icon }
    </div>
  )
}

export const ProductModal = () => {
  const back = useProductModal(state => state.back);
  const close = useProductModal(state => state.close);
  const stack = useProductModal(state => state.stack);
  const isOpen = useProductModal(state => state.isVisible);
  const stackItem = stack.length > 0 ? stack[stack.length - 1] : null;

  const router = useRouter();
  const location = useLocation();
  const { isError, isLoading, data } = useCart();
  const [ isCartSheetOpen, setCartSheetOpen ] = useState(false);
  const currentProductInCart = !isLoading && stackItem?.product && data?.items?.find(item => item.product.id === stackItem.product.id);

  const productAddedToast = (product) => toast.custom(t => (
    <ToastWithLink
      onClick={() => {
        toast.dismiss();
        setCartSheetOpen(true);
      }}
      visible={t.visible}
      actionTitle={'Перейти'}
      text={`Товар «${ product.title }» добавлен в корзину`}
    />
  ), {
    style: {
      marginBottom: '86px'
    },
    position: 'bottom-center',
    duration: 6000
  });

  useEffect(() => {
    if (stackItem?.returnUrl && stackItem.returnUrl === location.pathname + location.search) {
      back();
    }
  }, [ location.pathname, location.search ]);


  return (
    <Dialog
      as="div"
      open={isOpen}
      className=""
      onClose={() => {}}
    >
      <div className="fixed z-40 inset-0 bg-white" aria-hidden="true" />

      <div className="z-40 fixed inset-0 overflow-y-auto">
        <div className="fixed inset-0 overflow-y-auto">
          <Dialog.Panel
            className={
              cn(
                ' bg-white transition-all overflow-scroll',
              )
            }
            // className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
          >
            <CartMobileModal
              onClose={() => setCartSheetOpen(false)}
              visible={isCartSheetOpen}
            />
            <div className="fixed z-10 top-4 left-4" onClick={() => {
              if (stackItem?.returnUrl) {
                window.history.pushState({}, '', stackItem.returnUrl);
                back();
              } else {
                close();
              }
            }}>
              <IconButton icon={<ArrowLeftIcon/>}/>
            </div>
            {
              stackItem &&
              <div className="fixed left-0 bottom-0 z-30 w-full">
                {
                  isEligibleForDolyame(stackItem.product) &&
                  <div
                    style={{ boxShadow: '0px 0px 12px 4px rgba(190, 190, 190, 0.3)' }}
                    className="-mb-3 flex justify-between mx-2 pb-4 pt-1.5 px-3 rounded-t-2xl bg-white"
                  >
                    <DolyameLargeProductPrice className="w-full flex justify-between" initialPrice={stackItem.product.price}>
                      <a className="text-sm text-blue-600 pr-1">подробнее</a>
                    </DolyameLargeProductPrice>
                  </div>
                }
                <div
                  style={{ boxShadow: '0px 0px 12px 4px rgba(190, 190, 190, 0.1)' }}
                  className="border flex justify-between gap-10 rounded-t-2xl bg-white bg-opacity-95 p-3 backdrop-blur-sm items-center"
                >
                  <Price className={cn(currentProductInCart ? 'text-lg' : 'text-2xl', 'pl-5 font-medium')} price={stackItem.product.price}/>
                  <ProductCartActionButton
                    size="md"
                    product={stackItem.product}
                    onProductAdded={(product) => productAddedToast(product)}
                  />
                </div>
              </div>
            }
            {
              stackItem &&
              <div className="overflow-scroll">
                <ProductModalContent product={stackItem.product}/>
                <div className="h-20 w-2"/>
              </div>
            }
            {
              !stackItem &&
              <div className="flex items-center justify-center h-screen">
                <Loading className="absolute" height={40} width={40} color="#2563EB" type="spin"/>
              </div>
            }
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  )
}
