import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import { HoverPopover } from 'components/common';
import styles from 'components/layout/HeaderV2/Header.module.css';
import { useRouter } from 'next/router';
import { useCart } from 'query/orders';
import { forwardRef } from 'react';
import Loading from 'react-loading';

import { CartPopoverContent } from './CartPopoverContent';


const TargetButton = forwardRef((props, ref) => {
  const router = useRouter();
  const { isLoading, data } = useCart();

  return (
    <div
      ref={ ref }
      onClick={ () => {
        if (!isLoading && data?.items?.length) {
          router.push('/cart');
        }
      } }
      color="transparent"
      className="relative text-lg gap-1.5 transition ease-in-out p-4 -mr-4"
      { ... props }
    >
      <ShoppingBagIcon className={ styles.cartBtn }/>
      {
        isLoading && <Loading className="absolute ml-7" height={ 20 } width={ 20 } color="black" type="spin"/>
      }
      {
        (data?.items.length > 0) &&
          <div className="absolute w-5 text-sm h-5 text-white rounded-full top-1 right-1 bg-blue-600">
            { data.items.length }
          </div>
          // : <span className={cn(isLoading && 'opacity-0')}>Корзина</span>
      }
    </div>
  )
});

TargetButton.displayName = 'TargetButton';

export const CartPopover = ({ hidden }) => {
  if (hidden) {
    return null;
  }

  return (
    <HoverPopover
      placement="bottom-end"
      offsetLeft={40}
      offsetTop={-12}
      target={<TargetButton/>}
    >
      <div className="p-6 mt-3 bg-white rounded-2xl flex flex-col gap-2 shadow-2xl w-96 space-y-3">
        <CartPopoverContent/>
      </div>
    </HoverPopover>
  )
}
