export const MODALS = {
  CART_DESKTOP: 'cart',
  CART_MOBILE: 'cart',
  MAP: 'map',
  PAYMENT: 'payment',
  POSTCARD: 'postcard',
  PRODUCT: 'product',
  STORY: 'story',
  CALL: 'call',
  CITY: 'city',
  DELIVERY_ADDRESS: 'delivery_address',
  DELIVERY_CONTACTS: 'delivery_contacts',
  DELIVERY_DATE: 'delivery_date',
  DELIVERY_RECEIVER: 'delivery_receiver',
  HINT: 'hint',
  ONE_CLICK_ORDER: 'one_click_order',
  PAYMENT_METHOD_MOBILE: 'payment_method_mobile',
  CONTACTS: 'contacts'
};
