import cn from 'classnames';
import { getInitialShippingDate } from 'components/cart/utils';
import { Button, InputDate } from 'components/common';
import { MODALS } from 'components/modals/constants';
import { useCartData } from 'hooks';
import moment from 'moment';
import { useCart, useUpdateCart } from 'query/orders';
import { useEffect, useState } from 'react';


export const IDENTIFIER = MODALS.DELIVERY_DATE;

const DateBookmark = ({ title, isActive, caption, ...props }) => {
  return (
    <div
      {...props}
      className={
        cn(
          isActive && 'bg-gradient-to-bl from-blue-400/90 to-blue-500 text-white border-blue-400',
          'text-sm bg-gray-100 h-20 rounded-xl flex flex-col justify-between p-2'
        )
      }
    >
      <span className="font-medium">{ title }</span>
      { caption && <span className="text-end">{ caption }</span> }
    </div>
  )
}

export function DeliveryDateModal({ onChange }) {
  const { data } = useCart();
  const { mutate, isSuccess, isLoading } = useUpdateCart();
  const [ cartData, setCartData ] = useCartData();
  const [ shippingDate, setShippingDate ] = useState(getInitialShippingDate(data, cartData));
  const currentDate = moment();

  const setCartDataKey = (key, value) => {
    setCartData({
      ... cartData,
      [key]: value
    });
  }

  useEffect(() => {
    if (isSuccess === true) {
      onChange();
    }
  }, [ isSuccess ]);

  return (
    <div>
      <h3 className="mb-3">Когда доставить?</h3>
      <div className="flex flex-col gap-3">

        <p className="mb-3">
          Свяжемся с вами и уточним подходящее время, чтобы точно успеть
        </p>
        <InputDate
          min={currentDate.format('YYYY-MM-DD')}
          value={shippingDate}
          onChange={value => {
            setCartDataKey('selectedShippingDate', value ? value.toDate() : value);
            setShippingDate(value);
          }}
        />

        {/*<InputTime*/}
        {/*  value={shippingDate}*/}
        {/*  onChange={value => {*/}
        {/*    setCartDataKey('selectedShippingDate', value ? value.toDate() : value);*/}
        {/*    setShippingDate(value);*/}
        {/*  }}*/}
        {/*/>*/}

        <Button
          className="mt-4"
          color="blue"
          disabled={isLoading || !shippingDate}
          onClick={() => {
            mutate({
              uuid: data.uuid,
              selectedShippingDate: shippingDate.toISOString()
            });
          }}
        >
          {
            (!isLoading && !shippingDate) ? 'Укажите дату' : 'Сохранить'
          }
        </Button>
      </div>
    </div>
  )
}

