import { CitiesContext } from 'context';
import { useContext } from 'react';


const HIDDEN_BLOCK_IDS = (process.env.NEXT_PUBLIC_PRODUCT_PAGE_HIDDEN_BONUSES || '').split(',');


export const ProductBenefitsBlock = () => {
  const { currentCity } = useContext(CitiesContext);
  const BLOCKS = [
    // {
    //   title: `Доставка от 60 минут в ${currentCity.titleIn}`,
    //   description: 'наши курьеры бережно привезут заказ ко времени',
    //   icon: '/product/delivery.png',
    //   bgColor: 'linear-gradient(45deg, rgb(239 165 118 / 8%), rgb(239 165 118 / 3%))',
    //   textColor: '#742a00'
    // },
    {
      id: 'postcard',
      title: 'Бесплатная открытка',
      description: 'отличный способ оставить теплые воспоминания, подпишем от руки',
      icon: '/product/envelope.png',
      bgColor: 'linear-gradient(333deg, rgb(169 229 182 / 10%), rgb(169 229 182 / 6%))',
      textColor: '#006a17'
    },
    {
      id: 'approval',
      title: 'Фото готового букета',
      description: 'флорист отправит фото готового букета перед доставкой',
      icon: '/product/photo.png',
      bgColor: 'linear-gradient(45deg, rgb(138 224 233 / 10%), rgb(138 224 233 / 5%))',
      textColor: '#00595e'
    },
    {
      id: 'instruction',
      title: 'Памятка по уходу',
      description: 'вместе с букетом отправим инструкцию по уходу за цветами',
      icon: '/product/flower.png',
      bgColor: 'linear-gradient(45deg, rgb(247 160 181 / 8%), rgb(247 160 181 / 4%))',
      textColor: '#95062d'
    }
  ]

  return (
    <div className="grid lg:grid-cols-2 gap-4">
      {
        BLOCKS.filter(block => !HIDDEN_BLOCK_IDS.includes(block.id)).map((block, index) => (
          <div
            key={index}
            className="rounded-3xl p-5 flex flex-col gap-1"
            style={{
              background: block.bgColor,
              color: block.textColor
            }}
          >
            <h4>{ block.title }</h4>
            <div className="flex gap-2 h-full">
              <div className="flex-1 text-sm">
                { block.description }
              </div>
              <div className="self-end bg-right-bottom w-20 h-20 bg-contain bg-no-repeat" style={{ backgroundImage: `url(${block.icon})` }}/>
            </div>
          </div>
        ))
      }
    </div>
  )
}
