import { Button } from 'components/common';
import { MODALS } from 'components/modals/constants';
import { useFormik } from 'formik';
import { useCart, useUpdatePostCard } from 'query/orders';
import { useEffect } from 'react';
import { reachGoal } from 'utils/metrika';
import * as yup from 'yup';


export const IDENTIFIER = MODALS.POSTCARD;

export function PostCardModal({ setIsOpen }) {
  const { data } = useCart();
  const { mutate, isSuccess, isLoading } = useUpdatePostCard();
  const postCardText = data?.metadata?.find(({ key }) => key === 'postcard_text')?.value;

  const schema = yup.object().shape({
    text: yup.string().default(postCardText || '')
  });

  const form = useFormik({
    initialValues: schema.cast(),
    validationSchema: schema,
    onSubmit: ({ text }) => {
      mutate({
        uuid: data.uuid,
        text
      });

      reachGoal('post_card_updated', { text });
    }
  });

  useEffect(() => {
    if (isSuccess === true) {
      setIsOpen(false);
    }
  }, [ isSuccess ]);

  return (
    <div className="rounded-t-2xl">
      <div className="bg-white rounded-2xl p-4">
        <div>
          <h3 className="mb-3">Текст открытки</h3>
          <p className="text-gray-500">
            Подпишем открытку от руки и отправим её вместе с заказом
          </p>
          <form onSubmit={form.handleSubmit} className="flex flex-col gap-3 mt-5">
            <textarea
              rows={4}
              className="rounded-xl border-gray-200 outline-none"
              placeholder="Что написать?"
              name="text"
              value={form.values.text}
              onChange={form.handleChange}
            />
            <div className="flex gap-2">
              {
                (!!postCardText?.length) &&
                <Button
                  disabled={!form.values.text?.length || isLoading}
                  className="w-full"
                  color="gray"
                  onClick={() => form.setFieldValue('text', '')}
                >
                  Очистить
                </Button>
              }
              <Button
                disabled={isLoading}
                className="w-full"
                color="blue"
                type="submit"
              >
                Сохранить
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
