import classNames from 'classnames';
import { cloneElement } from 'react';

import styles from '../BottomBar.module.css';

export const BottomBarButton = ({ isActive, icon, title, ...props }) => {
  return (
    <div
      className={
        classNames(
          styles.button,
          isActive && 'text-blue-700'
        )
      }
      { ...props }
    >
      {
        icon ? cloneElement(icon, { className: 'w-6 h-6' }) : 'хуй'
      }
      <span className={ classNames('text-xs', !isActive && 'opacity-90') }>
        { title }
      </span>
    </div>
  )
}
