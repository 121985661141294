import classNames from 'classnames';
import { forwardRef, useState } from 'react';
import shortid from 'shortid';

import styles from './Input.module.css';


const Input = forwardRef(({ id, name, value, placeholder, inputClassName, className, ... props }, ref) => {
  const [ gName ] = useState(name || shortid.generate());

  return (
    <div
      style={{ height: '52px' }}
      className={
        classNames(
          'box-content',
          className
        )
      }
    >
      <input
        ref={ref}
        value={value}
        name={gName}
        id={id}
        autoFocus={false}
        className={
          classNames(
            styles.input,
            'border-gray-200 hover:border-gray-300 shadow-none rounded-xl px-4 pt-5 pb-1.5 w-full outline-none',
            inputClassName,
          )
        }
        placeholder=" "
        { ...props }
      />
      <label className={styles.label} htmlFor={gName}>{ placeholder }</label>
    </div>
  )
});

Input.displayName = 'Input';

export { Input };
