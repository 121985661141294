import { Field } from 'formik';
import InputMask from 'react-input-mask';

import { Input } from './Input';


export const InputPhone = ({ label, name, placeholder, ...props }) => {
  return (
    <Field name={name}>
      {
        ({ field }) => <InputMask
          { ...field }
          mask="+7 999 999 9999"
          maskChar={null}
          alwaysShowMask={false}
          permanents={[ 0, 1, 2, 6, 10 ]}
          inputMode="tel"
          {...props}
          // onChange={(e) => {
          //   console.log('input phone onChange', e?.target?.value);
          //   onChange(e);
          // }}
        >
          {
            inputProps => (
              <Input
                {...inputProps}
                label={label}
                type="tel"
                placeholder={placeholder}
              />
            )
          }
        </InputMask>
      }
    </Field>
  );
}
