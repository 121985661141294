import { Button, FormError, Input, InputPhone, Price } from 'components/common';
import { MODALS } from 'components/modals/constants';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useCart } from 'query/orders';
import { useState } from 'react';
import { sendForm } from 'services/api/utils.service';
import { getFormattedPhoneNumber, getProductPreview } from 'utils';
import { reachGoal } from 'utils/metrika';
import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup.string(),
  phoneNumber: yup.string().required('Укажите номер телефона').matches(/^\+\d \d{3} \d{3} \d{4}$/, 'Введите полный номер телефона').default('')
});


export const IDENTIFIER = MODALS.ONE_CLICK_ORDER;

export function OneClickOrderModal({ product }) {
  const { data, isLoading } = useCart();
  const [ submittedPhoneNumber, setSubmittedPhoneNumber ] = useState(false);

  if (isLoading) return null;

  return (
    <div className="p-5 sm:p-6 flex flex-col gap-3 relative">
      {
        submittedPhoneNumber &&
        <div className="w-full left-0 top-0 h-full absolute bg-white z-10 flex flex-col text-center justify-center items-center">
          <img src={'/e/270c-fe0f.png'} alt="" width="100px"/>
          <p className="mt-6 text-lg">
            Спасибо!
            <br/>
            Свяжемся по номеру { getFormattedPhoneNumber(submittedPhoneNumber) }
          </p>
        </div>
      }
      <h3>Заказ в один клик</h3>
      <p className="mb-2 text-neutral-600">Оставьте свой номер телефона и имя, мы поможем оформить заказ</p>
      {
        product &&
        <div className="rounded-xl mb-4 flex-row flex gap-3">
          <img alt="" className="w-12 h-12 rounded-lg bg-gray-100" src={ getProductPreview(product) }/>
          <div>
            <div className="font-medium">{ product.title }</div>
            <Price price={product.price}/>
          </div>
        </div>
      }

      <Formik
        initialValues={{
          name: data?.createdBy?.fullName || '',
          phoneNumber: data?.createdBy?.phoneNumber || ''
        }}
        validationSchema={schema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={async ({ name, phoneNumber }) => {
          reachGoal('one_click_order_applied', {
            product_id: product.id,
            title: product.title,
            phone_number: phoneNumber
          });

          // same shape as initial values
          await sendForm('one_click_order', {
            name: name,
            phoneNumber: phoneNumber,
            productId: product.id
          });

          setSubmittedPhoneNumber(phoneNumber);
        }}
      >
        <Form className="flex flex-col gap-3">
          <Field
            as={Input}
            placeholder="Ваше имя"
            name="name"
          />
          <ErrorMessage name="name" component={FormError}/>
          <InputPhone name="phoneNumber" placeholder="Ваш номер телефона"/>
          <ErrorMessage name="phoneNumber" component={FormError}/>
          <Button
            color="blue"
            type="submit"
            // disabled={isLoading}
          >
            Отправить
          </Button>
        </Form>
      </Formik>
    </div>
  )
}

