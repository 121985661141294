import { TruckIcon } from '@heroicons/react/24/outline';
import { Index } from 'components/cart';
import { Button, Price } from 'components/common';
import { CartPopoverEmptyGap } from 'components/popovers/CartPopover/CartPopoverEmptyGap';
import Link from 'next/link';
import { useCart } from 'query/orders';

export function CartPopoverContent() {
  const { isLoading, isError, data, refetch } = useCart();

  if (isError) {
    return (
      <div onClick={() => refetch()} className="flex flex-col py-6 px-6 text-center gap-3">
        <p>Произошла ошибка при получении корзины</p>
        <Button color="invisible" className="text-blue-700">Перезагрузить</Button>
      </div>
    );
  }

  if (isLoading || !data?.items?.length) {
    return <CartPopoverEmptyGap/>;
  }

  return (
    <>
      <Index className="text-sm"/>
      <div className="flex gap-3 items-center">
        <div className="w-12 h-12 flex justify-center items-center bg-blue-50 rounded-lg">
          <TruckIcon className="w-7 h-7 text-blue-700/60"/>
        </div>
        <div className="-mt-1 opacity-90">
          <div className="text-sm font-medium text-gray-700">
            Доставка&nbsp;
            {
              data.deliveryArea?.estimateDeliveryTime &&
              `${data.deliveryArea.estimateDeliveryTime}-${data.deliveryArea.estimateDeliveryTime + 30} минут`
            }
          </div>
          <div>
            {
              data.price.delivery > 0
                ? <Price className="font-medium" size="sm" price={data?.price?.delivery || 0}/>
                : <span className="mt-2 font-normal text-sm">Бесплатно по центру Сочи</span>
            }
          </div>
        </div>
      </div>
      <Link prefetch={false} href={'/cart'}>
        <Button className="w-full pt-4 gap-1.5" aria-label="Перейти в корзину" color="blue">
          Перейти в корзину
          <Price className="font-medium" size="md" price={data.price.total}/>
        </Button>
      </Link>
    </>
  )
}
