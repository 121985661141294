import cn from 'classnames';

import styles from '../CategoriesListItem/CategoriesListItem.module.css';


export function CategoryIcon({ className, isSpecial, slug }) {
  return (
    <div
      className={cn(styles.icon, className, isSpecial ? 'from-yellow-50/60 to-green-100/60' : 'from-blue-50/80 to-blue-100/20')}
    >
      <div className="absolute w-8 h-8 bottom-0 right-0">
        <img src={`/categories-icons/${slug}.png`} />
      </div>
    </div>
  )
}
