import Image from 'next/image'

const loader = ({ src, width, squared }) => {
  const options = squared ? `rs:fill-down:${width}:${width}:1` : `w:${width}`;

  return src.replace(/\{options}/, options);
}

export const LazyImage = ({ src, size, alt = 'Изображение', squared, className, ...rest }) => {
  return (
    <div className={className}>
      <Image
        loader={params => loader({
          ...params,
          width: size,
          squared
        })}
        src={src}
        alt={alt}
        width={squared ? size  : '100%'}
        height={squared ? size : '100%'}
        { ...rest }
      />
    </div>
  )
}
