import axios from 'axios';
import toast from 'react-hot-toast';


const { NEXT_PUBLIC_API_URL = 'https://api.flopus.ru/' } = process.env;


export const createAPIClient = (baseURL, useServiceToken) => {
  const client = axios.create({
    baseURL,
    headers: {
      ... (useServiceToken) && {
        authorization: `Token ${process.env.API_SERVICE_TOKEN}`
      }
    }
  });

  client.interceptors.response.use(response => response.data, (error) => {
    if (typeof window !== 'undefined') {
      let errorMessage = error.response?.data?.message;

      if (!errorMessage?.match(/[а-я]/)) {
        errorMessage = 'Не удалось выполнить запрос';
      }

      toast.error(errorMessage);
    }

    return Promise.reject(error);
  });

  return client;
}


export const APIClient = createAPIClient(NEXT_PUBLIC_API_URL);
export const ServiceAPIClient = createAPIClient(NEXT_PUBLIC_API_URL, true);
export const NextAPIClient = createAPIClient('/api/');
