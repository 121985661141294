import cn from 'classnames';


export const NavigationButton = ({ children, className, ...rest }) => {
  return (
    <div className={cn('hover:cursor-pointer text-black hover:text-blue-700 p-4', className)} {...rest}>
      { children }
    </div>
  )
}
