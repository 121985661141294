import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import styles from 'components/layout/HeaderV2/Header.module.css';
import { useCart } from 'query/orders';
import { forwardRef } from 'react';
import Loading from 'react-loading';


const CartButton = forwardRef((props, ref) => {
  const { isLoading, data } = useCart();

  return (
    <button
      ref={ ref }
      aria-label="Перейти в корзину"
      color="transparent"
      className="relative mt-1 text-lg gap-1.5 transition ease-in-out"
      disabled={ isLoading }
      { ... props }
    >
      <ShoppingBagIcon className={ styles.cartBtn }/>
      {
        isLoading && <Loading className="absolute -top-3 -right-3" height={ 20 } width={ 20 } color="black" type="spin"/>
      }
      {
        (data?.items.length > 0) &&
        <div className="absolute w-5 text-sm h-5 text-white rounded-full -top-3 -right-3 bg-blue-600">
          { data.items.length }
        </div>
      }
    </button>
  )
});

CartButton.displayName = 'CartButton';


export {
  CartButton
};
