import { createOrganizationLinkedData } from 'utils';


export function OrganizationLinkedData({ currentCity, hostname, ... props }) {
  return (
    <script
      { ...props }
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          createOrganizationLinkedData({
            hostname,
            cityName: currentCity.titleIn,
            keywords: currentCity.seoKeywords,
            description: currentCity.seoDescription
          })
        )
      }}
    />
  )
}
