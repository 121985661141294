import { Switch as BaseSwitch } from '@headlessui/react';


export function Switch({ enabled, onChange, label }) {
  return (
    <BaseSwitch
      checked={enabled}
      onChange={onChange}
      className={`${
        enabled ? 'bg-blue-500' : 'bg-gray-200'
      } relative inline-flex h-7 w-14 items-center rounded-full`}
    >
      {
        label && <span className="sr-only">{ label }</span>
      }
      <span
        className={`${
          enabled ? 'translate-x-8' : 'translate-x-1'
        } inline-block h-5 w-5 transform rounded-full bg-white transition`}
      />
    </BaseSwitch>
  )
}
