import { Price } from 'components/common';
import { useCart } from 'query/orders';

export const CartPriceSummary = () => {
  const { data } = useCart();

  return (
    <dl className="space-y-3">
      <div className="flex items-center justify-between">
        <dt>Подытог</dt>
        <Price price={data.price.subtotal}/>
      </div>
      {
        data?.location &&
        <div className="flex items-center justify-between">
          <dt>Доставка</dt>
          {
            data?.location
              ? (data?.price?.delivery ? <Price price={data.price.delivery}/> : 'Бесплатно')
              : 'Укажите адрес'
          }
        </div>
      }
      {
        !data?.location &&
        <div className="flex items-center justify-between">
          <dt>Доставка</dt>
          Укажите адрес
        </div>
      }
      <div className="flex items-center justify-between">
        <dt className="font-medium">Всего</dt>
        <Price className="font-semibold" price={data.price.total}/>
      </div>
    </dl>
  )
}
