import { useRouter } from 'next/router';
import stringify from 'qs-stringify';
import { useCallback } from 'react';

export const RETURN_HREF_QUERY_PARAM = '_UCR_return_href';

/**
 * https://github.com/toomuchdesign/next-use-contextual-routing
 * During contextual routing browser URL will be controlled by Next Router's "as" prop
 * while the page actually rendered is defined by Next Router's "href" prop.
 *
 * During contextual navigation Next Router's behaves as follows:
 * router.asPath:   /item/3               reflects current URL and updates at each page change
 * router.pathname: /search/[terms]       stay the same as long as initial page doesn't change
 * router.query:    {"terms": "foo-bar"}  same as above
 */
export function useContextualRouting() {
  const router = useRouter();
  const returnHrefQueryParam =
    typeof router.query[RETURN_HREF_QUERY_PARAM] === 'string'
      ? router.query[RETURN_HREF_QUERY_PARAM]
      : undefined;
  const watchedQuery = Object.assign({}, router.query);
  delete watchedQuery[RETURN_HREF_QUERY_PARAM];

  const returnHref = returnHrefQueryParam ?? router.asPath;
  const queryHash = JSON.stringify(watchedQuery);
  const makeContextualHref = useCallback(
    (extraParams) =>
      router.pathname +
  '?' +
  stringify(
    Object.assign({}, router.query, extraParams, {
      [RETURN_HREF_QUERY_PARAM]: returnHref,
    })
  ),
    [ queryHash, returnHref ]
  );

  return { returnHref, makeContextualHref };
}
