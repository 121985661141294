import cn from 'classnames';
import { resizeTemplatedImage } from 'utils';


export function ProductFirstImage({ images, size = 50, className }) {
  const image = images.find(image => image.type === 'image');
  const url = image ? resizeTemplatedImage(image.url, Math.round(size * 1.2), Math.round(size * 1.2)) : null;

  return (
    <div
      className={cn(className, 'rounded-xl bg-gray-100')}
      style={{
        width: size + 'px',
        height: size + 'px'
      }}
    >
      {
        url &&
        <img
          alt=""
          src={url}
          className="w-full h-full rounded-xl"
        />
      }
    </div>
  );
}
