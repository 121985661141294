import { useEffect, useState } from 'react';


export const useThirdPartyCookieCheck = () => {
  const [ isSupported, setIsSupported ] = useState(false);

  useEffect(() => {
    const iframe = document.createElement('iframe');

    iframe.id = '3pc';
    iframe.src = 'https://3pc.darkreef.ru/'; //Add your hosted domain url here
    iframe.style.display = 'none';
    iframe.style.position = 'fixed';

    document.body.appendChild(iframe);

    window.addEventListener(
      'message',
      function listen(event) {
        if (event.data === '3pc-ok' || event.data === '3pc-failed') {
          setIsSupported(event.data === '3pc-ok');

          document.body.removeChild(iframe);

          window.removeEventListener('message', listen);
        }
      },
      false
    );
  }, []);

  return isSupported;
};
