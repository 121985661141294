import { useInfiniteQuery, useQuery } from 'react-query';
import { ProductsService } from 'services/api';


const Products = new ProductsService();


export const useCategory = (id) => useQuery(
  [ 'category', id ],
  () => id ? Products.getCategory(id) : null
);

export const useQueryPaginatedProducts = ({ initialData, skip, pageSize, queryFilters }) => {
  const key = [ 'paginated-products', queryFilters ];

  return useInfiniteQuery({
    staleTime: 0,
    cacheTime: 0,
    queryKey: key,
    initialData,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    queryFn: ({ pageParam = 0, meta }) => {
      return Products.getMany({
        ... queryFilters,
        count: pageSize,
        skip: pageParam
      });
    },
    getNextPageParam: (lastPage, allPages) => skip
  })
}

export const useProduct = (id) => useQuery(
  [ 'product', id ],
  () => Products.getById(id)
);

export const useProductIds = (ids) => useQuery(
  [ 'product-ids', { ids } ],
  () => Products.getByIds(ids)
);
