import cn from 'classnames';
import { Price, QtyModificator } from 'components/common';
import { useAddProduct, useCart, useEditProduct } from 'query/orders';
import { getPreviewFromImageList } from 'utils/image';

export function CartProductList({ previewClassName, className, changeQtyDisabled }) {
  const { data, isLoading, isError } = useCart();
  const { mutate, isEditing } = useEditProduct();
  const { isLoading: isAdding } = useAddProduct();

  const editCartItem = (itemUUID, newQty) => {
    mutate({
      itemUUID,
      qty: newQty
    });
  }

  return (
    <>
      {
        data?.items?.filter(({ optimistic }) => !optimistic).map(item => {
          const url = getPreviewFromImageList(item.product.images, 100, 100);

          return (
            <div
              key={ item.uuid }
              className={
                cn(
                  'gap-3 flex shrink-0 items-center justify-between group',
                  className
                )
              }
            >
              {
                url
                  ? <img alt="" className={cn(previewClassName, 'shrink-1 w-12 h-12 block rounded-lg')} src={url}/>
                  : <div className={cn(previewClassName, 'shrink-1 w-12 h-12 bg-gray-100 rounded-lg')}/>
              }
              <div className="truncate flex-1 items-center space-y-1">
                <div className="truncate font-medium">
                  { item.product.title }
                </div>
                <Price
                  className={
                    cn(
                      'font-medium',
                      item.product.discountPrice && 'text-red-500'
                    )
                  }
                  size="sm"
                  price={ item.price }
                />
              </div>
              {
                !changeQtyDisabled &&
                <QtyModificator
                  className="flex md:hidden group-hover:flex max-w-fit flex-1 h-10"
                  size="sm"
                  currentQty={item.qty}
                  disabled={isLoading || isEditing || isAdding}
                  onQtyChanged={newQty => editCartItem(item.uuid, newQty)}
                />
              }
            </div>
          )
        })
      }
    </>
  )
}
