import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import cn from 'classnames';
import { Dialog } from 'components/modals';

import styles from './QuickSearchContainer.module.css';
import { SearchInput } from './SearchInput';


export function QuickSearchContainer({ layout, isVisible, setVisible, onChange, value, children }) {
  if (layout === 'mobile') {
    return (
      <Dialog
        isOpen={isVisible}
        className="rounded-t-none w-full h-full"
      >
        <div className={ styles.wrapper }>
          <div className={ styles.header }>
            <div
              className={ styles.backButton }
              onClick={() => setVisible(false)}
            >
              <ArrowLeftIcon className="w-7 h-7"/>
            </div>
            <SearchInput
              className="w-full mr-4"
              value={value}
              onChange={(value) => onChange(value)}
            />
          </div>
          <div className="mt-12 px-4">
            { children }
          </div>
          <div className="h-24 w-full"/>
        </div>
      </Dialog>
    );
  }

  return (
    <div
      className={cn(isVisible ? 'flex' : 'hidden', styles.wrapper_desktop)}
      style={{ boxShadow: '0px 22px 15px rgb(0, 0, 0, 0.05)' }}
    >
      { children }
    </div>
  );
}
