import { Emoji } from 'components/common';

export const CartAddress = (cart) => {
  if (!cart?.address?.street) {
    return {
      title: 'Укажите адрес доставки'
    }
  }

  let subtitle;

  if (!cart?.address?.houseNumber) {
    subtitle = <>Укажите номер дома <Emoji size={3}>1f64f</Emoji></>
  } else {
    const parts = [];

    if (cart?.address?.entrance) {
      parts.push(`подъезд ${cart.address.entrance}`);
    }

    if (cart?.address?.floor) {
      parts.push(`этаж ${cart.address.floor}`);
    }

    if (cart?.address?.apartment) {
      parts.push(`${cart.address.apartmentType || 'квартира'} ${cart.address.apartment}`);
    }

    subtitle = parts.join(', ');
  }

  const parts = [ cart.address.street ];

  if (cart.address?.houseNumber) {
    parts.push(cart.address.houseNumber);
  }

  return {
    title: parts.join(', '),
    subtitle
  }
}
