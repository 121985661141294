import { CheckIcon, GiftIcon, TruckIcon } from '@heroicons/react/24/outline';
import { CitiesContext } from 'context';
import { useContext } from 'react';

export const ProductConditions = ({ className }) => {
  const { currentCity } = useContext(CitiesContext);

  return (
    <div className={className}>
      <div className="flex gap-3">
        <TruckIcon className="w-7 h-7"/>
        <div>
          <p className="leading-7 font-medium">Доставка курьером</p>
          {/* <p className="text-sm text-gray-500">{ currentCity.deliveryTimeFrom } &middot; { currentCity.productDeliveryConditions }</p> */}
          <p className="text-sm text-gray-500">Бесплатно по центру Сочи</p>
        </div>
      </div>
      <div className="flex gap-3">
        <GiftIcon className="w-7 h-7"/>
        <div>
          <p className="leading-7 font-medium">Бесплатная открытка</p>
        </div>
      </div>
      <div className="flex gap-3">
        <CheckIcon className="w-7 h-7"/>
        <div>
          <div>
            <p className="leading-7 font-medium">Цветы в наличии <span className="text-blue-600">— осталось мало</span></p>
          </div>
        </div>
      </div>
    </div>
  )
}
