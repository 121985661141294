import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { QuickSearchResults } from 'components/layout/QuickSearch/QuickSearchResults';
import { SearchContext } from 'context';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef } from 'react';
import * as yup from 'yup';

import styles from '../../QuickSearch/QuickSearchContainer.module.css';

const MobileLayout = ({ input, onClose }) => {
  return (
    <div className={ styles.wrapper }>
      <div className={ styles.header }>
        <div
          className={ styles.backButton }
          onClick={() => onClose()}
        >
          <ArrowLeftIcon className="w-7 h-7"/>
        </div>
        { input }
      </div>
      <div className="mt-12 px-4">
        <QuickSearchResults close={() => onClose()}/>
      </div>
      <div className="h-24 w-full"/>
    </div>
  )
}

export function QuickSearch({ layout, onClose }) {
  const router = useRouter();
  const formik = useFormik({
    validationSchema: yup.object().shape({
      query: yup.string()
    }),
    initialValues: {
      query: ''
    },
    onSubmit: async ({ query }) => {
      if (query?.length) {
        ref.current.blur();
        await router.push(`/search?query=${query}`);
        onClose();
      }
    },
  });

  const { searchQuery, setSearchQuery, setSearchVisible } = useContext(SearchContext);
  const ref = useRef(null);
  const input = (
    <form onSubmit={formik.handleSubmit}>
      <input
        ref={ref}
        name="query"
        autoComplete="off"
        className="font-semibold border-none text-xl shadow-none md:text-3xl placeholder:text-gray-300"
        placeholder="Поиск"
        value={searchQuery}
        onChange={(event) => {
          setSearchVisible(true);
          setSearchQuery(event.target.value);
          formik.handleChange(event);
        }}
      />
    </form>
  );

  useEffect(() => ref.current.focus(), []);

  return layout === 'mobile'
    ? <MobileLayout input={input} onClose={onClose}/>
    : (
      <>
        { input }
        <QuickSearchResults close={onClose}/>
      </>
    )
}
