import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';
import { getNoun } from 'utils';

import styles from './QtyModificator.module.css';


export const QtyModificator = ({ className, currentQty = 0, onQtyChanged, disabled, maxQty, size }) => {
  const canDecrease = !disabled && (currentQty - 1 >= 0);
  const canIncrease = !disabled && (!maxQty || (maxQty && currentQty + 1 >= maxQty));

  const Qty = () => (size === 'lg')
    ? <p>{ currentQty } { getNoun(currentQty, 'товар', 'товара', 'товаров') } <Link className="text-blue-700 hover:text-blue-600" href={'/cart'}>в корзине</Link></p>
    : <p>{ currentQty }</p>;

  return (
    <div
      className={classNames(styles.container, size === 'lg' && 'h-12', className)}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div
        className={
          classNames(
            styles.btn,
            'pl-4',
            (!disabled && canDecrease) && 'hover:bg-gradient-to-r'
          )
        }
        onClick={() => (canDecrease && onQtyChanged) && onQtyChanged(currentQty - 1)}
      >
        <MinusIcon
          className={
            classNames(
              size === 'sm' ? 'h-4' : 'h-6',
              (!canDecrease || disabled) && 'opacity-20'
            )
          }
        />
      </div>
      <div
        className={
          classNames(
            size === 'sm' ? '' : 'text-lg',
            styles.qty
          )
        }
      >
        <Qty/>
        {/*{*/}
        {/*  disabled &&*/}
        {/*  <div className="-mt-6 opacity-70 w-full top-0 flex items-center justify-center">*/}
        {/*    <Loading height={20} width={20} color="black" type="spin"/>*/}
        {/*  </div>*/}
        {/*}*/}
      </div>
      <div
        className={
          classNames(
            styles.btn,
            'justify-end pr-4',
            (!disabled && canIncrease) && 'hover:bg-gradient-to-l'
          )
        }
        onClick={() => (canIncrease && onQtyChanged) && onQtyChanged(currentQty + 1)}
      >
        <PlusIcon className={
          classNames(
            size === 'sm' ? 'w-4 h-4' : 'w-6 h-6',
            (!canIncrease || disabled) && 'opacity-20'
          )
        }/>
      </div>
    </div>
  )
}
