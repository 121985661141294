export const reachGoal = (identifier, params) => {
  if (typeof window !== 'undefined') {
    if (window.ym) {
      ym(92458157, 'reachGoal', identifier, params);
    }

    if (window._tmr) {
      _tmr.push({
        type: 'reachGoal',
        id: 3290755,
        goal: identifier
      });
    }
  }
}
