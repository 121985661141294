import { APIClient, NextAPIClient } from 'utils/api-client';


export const globalSearch = (query, countPerGroup = 6) => {
  return APIClient.get('/search', {
    params: {
      query,
      count: countPerGroup
    }
  });
}

export const sendContactClickEvent = async (contactType, source) => {
  try {
    await NextAPIClient.post('/forms', {
      type: 'reveal_phone_number',
      contactType,
      path: location.pathname,
      source
    });
  } catch {}
}

export const sendForm = (formName, params) => {
  return NextAPIClient.post('/forms', {
    type: formName,
    ... params
  });
}
