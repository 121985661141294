import { APIClient, NextAPIClient, ServiceAPIClient } from 'utils/api-client';


class APIService {
  constructor({ useServiceToken, useNextBackend } = {}) {
    if (useNextBackend) {
      this.api = NextAPIClient;
    } else if (useServiceToken) {
      this.api = ServiceAPIClient;
    } else {
      this.api = APIClient;
    }
  }
}

export default APIService;
