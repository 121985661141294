import { CursorArrowRippleIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import { BlockDivider } from 'components/common';
import { ModalWrapper } from 'components/modals/components/ModalWrapper';
import { HintModal } from 'components/modals/HintModal';
import { OneClickOrderModal } from 'components/modals/OneClickOrderModal';
import { DetailedProductComponent, ProductConditions, ProductContactsBlock } from 'components/products';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getRawImage } from 'utils';


export const ProductModalContent = ({ product }) => {
  const [ isHintModalOpen, setHintModalOpen ] = useState(false);
  const [ isOneCLickModalOpen, setOneCLickModalOpen ] = useState(false);
  const [ currentSlide, setCurrentSlide ] = useState(0);
  const images = product.images.filter(({ type }) => type === 'image');

  return (
    <div className="flex flex-col">
      <ModalWrapper
        modalStyle={{ maxWidth: '500px' }}
        isOpen={isOneCLickModalOpen}
        setIsOpen={setOneCLickModalOpen}
        titleComponent={true}
        onDismiss={() => setOneCLickModalOpen(false)}
      >
        <OneClickOrderModal
          product={product}
          onClose={() => setOneCLickModalOpen(false)}
        />
      </ModalWrapper>
      <ModalWrapper
        sheetClass="bg-header"
        modalStyle={{ maxWidth: '500px' }}
        isOpen={isHintModalOpen}
        setIsOpen={setHintModalOpen}
        titleComponent={true}
        onDismiss={() => setHintModalOpen(false)}
      >
        <HintModal product={product}/>
      </ModalWrapper>
      <div>
        <Swiper
          autoHeight={true}
          slidesPerGroup={1}
          onRealIndexChange={swiper => setCurrentSlide(swiper.realIndex)}
          // modules={isTouchDevice ? [] : [ Navigation ]}
          slidesPerView={1}
        >
          {
            images.map((image, index) => (
              <SwiperSlide
                key={index}
              >
                <img
                  {
                    ... (index > 0) && {
                      loading: 'lazy'
                    }
                  }
                  className="bg-gray-50"
                  width={image.width}
                  height={image.height}
                  alt={product.title}
                  src={getRawImage(image.url)}
                />
              </SwiperSlide>
            ))
          }
        </Swiper>
        {
          images.length > 1 &&
          <div className="flex items-center gap-1 pt-2 border-r justify-center">
            {
              images.map((_, index) =>
                <div key={index} className={cn(index === currentSlide ? 'w-2 h-2' : 'w-1.5 h-1.5', 'transition-all bg-black rounded-full')}/>
              )
            }
          </div>
        }
      </div>
      <div className="relative">
        <div className="p-4 pb-0">
          <h3>{product.title}</h3>
          <div className="space-y-3 mt-4">
            <button
              size="sm"
              className="flex gap-1.5 items-center"
              onClick={() => setOneCLickModalOpen(true)}
            >
              <CursorArrowRippleIcon className="text-gray-600 w-5 h-5"/>
              Быстрый заказ
            </button>
            <button
              size="sm"
              className="flex gap-1.5 items-center"
              onClick={() => setHintModalOpen(true)}
            >
              <EnvelopeIcon className="text-gray-600 w-5 h-5"/>
              Намекнуть о подарке
            </button>
          </div>
        </div>
        <BlockDivider/>
        <ProductConditions className="px-5 space-y-4"/>
        {
          (product.components.length > 0) &&
          <>
            {/* TODO: сюда засунуть кнопку для корзины или открывать штору сразу */}
            <BlockDivider/>
            <div className="px-4">
              <div className="flex gap-3 items-center mb-2">
                <h4>{ product.category.componentsTitle || 'Состав' }</h4>
              </div>
              <DetailedProductComponent className="p-2" components={product.components}/>
            </div>
          </>
        }
        <BlockDivider/>
        <div className="px-5 overflow-x-hidden">
          <ProductContactsBlock/>
        </div>
      </div>
    </div>
  )
}
