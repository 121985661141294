import { useFloating } from '@floating-ui/react-dom';
import { RectangleStackIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import { CategoriesDesktopContent } from 'components/categories';
import { useState } from 'react';

export function CategoriesPopover({ className }) {
  const { x, y, reference, floating, strategy } = useFloating();
  const [ isVisible, setVisible ] = useState(false);
  const [ timeoutId, setTimeoutId ] = useState(null);

  const hideCategoriesPopup = () => {
    if (!timeoutId) {
      setTimeoutId(setTimeout(() => setVisible(false), 500));
    }
  };

  const showCategoriesPopup = () => {
    if (timeoutId) {
      clearInterval(timeoutId);
      setTimeoutId(null);
    }

    setVisible(true);
  }

  return (
    <div
      className="hidden md:block"
      onMouseEnter={() => showCategoriesPopup()}
      onMouseLeave={() => hideCategoriesPopup()}
    >
      <button
        ref={reference}
        aria-label="Каталог"
        color="invisible"
        className={
          cn(
            'flex gap-2 font-medium text-blue-700',
            className
          )
        }
      >
        <RectangleStackIcon className="w-6 h-6 opacity-80"/>
        Каталог
      </button>
      <div
        ref={ floating }
        className={cn('transition-all', isVisible ? 'opacity-100' : 'opacity-0' )}
        style={ {
          visibility: isVisible ? 'visible' : 'hidden',
          position: strategy,
          top: y + 10 ?? 0,
          left: x + 460 ?? 0,
        } }
      >
        <nav className="z-20 absolute p-5 mt-3 transform left-0 relative bg-white rounded-2xl shadow-2xl">
          <CategoriesDesktopContent onSelect={() => {}}/>
        </nav>
      </div>
    </div>
  );
}
