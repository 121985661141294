import cn from 'classnames';
import moment from 'moment';
import { useRef } from 'react';
import { formatShippingDate } from 'utils';

import { Input } from './Input';
import styles from './InputDate.module.css';

export const InputDate = ({ min, value, onChange, className }) => {
  const inputRef = useRef(null);
  // const [ date, setDate ] = useState(dayjs(props.initialValue || new Date()).format('YYYY-MM-DD'));

  // useEffect(() => {
  //   console.log('changed', date)
  //   setStringDate(dayjs(date).format('YYYY-MM-DD'));
  // }, [ date ]);

  return (
    <div className={cn(className, 'relative')}>
      <input
        type="date"
        ref={inputRef}
        className={cn(styles.control, 'opacity-0 z-10 outline-none rounded-xl absolute w-full h-full')}
        placeholder=""
        onChange={e => {
          const newValue = e?.target?.value || null;

          if (!newValue) {
            return onChange(null);
          }

          const newDate = moment(newValue);
          const fullDate = moment(value)
            .set('year', newDate.get('year'))
            .set('month', newDate.get('month'))
            .set('date', newDate.get('date'));

          onChange(fullDate);
        }}
        value={moment(value).format('YYYY-MM-DD')}
        min={min}
      />
      <Input
        disabled
        inputClassName="text-black hover:border-gray-400 opacity-100 bg-white cursor-pointer"
        placeholder="Дата доставки"
        value={value ? formatShippingDate(new Date(value)) : ''}
      />
    </div>
  )
}
