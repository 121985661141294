import cn from 'classnames';
import { DolyameModal, ModalWrapper } from 'components/modals';
import { useState } from 'react';
import { formatPrice } from 'utils';


export const DolyameProductPrice = ({ className, initialPrice }) => {
  return (
    <div className={cn(className, 'whitespace-nowrap text-gray-600 hover:text-blue-700 cursor-pointer  flex items-center gap-2 text-sm')}>
      <img className="opacity-80 bg-gray-200 p-1 rounded-md w-5 h-5" src={'/dolyame/logo_s.svg'}/>
      <p>долями по { formatPrice(Math.round(initialPrice / 4)) } ₽</p>
    </div>
  )
}

export const DolyameLargeProductPrice = ({ className, children, initialPrice, ...props }) => {
  const [ isDolyameModalOpen, setDolyameModalOpen ] = useState(false);

  return (
    <>
      <ModalWrapper
        sheetClass="bg-header"
        isOpen={isDolyameModalOpen}
        setIsOpen={setDolyameModalOpen}
        modalStyle={{ maxWidth: '700px' }}
        onDismiss={() => setDolyameModalOpen(false)}
      >
        <DolyameModal price={initialPrice}/>
      </ModalWrapper>
      <div
        className={cn(className, 'cursor-pointer hover:opacity-80')}
        onClick={() => setDolyameModalOpen(true)}
        { ...props }
      >
        <div className="font-semibold flex gap-1.5 items-center">
          <img className="h-3.5" src={'/dolyame/logo-black.svg'}/>
          <p className="-mt-0.5">по { formatPrice(Math.round(initialPrice / 4)) } ₽</p>
        </div>
        { children }
      </div>
    </>
  )
}
