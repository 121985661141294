import classNames from 'classnames';
import { formatPrice } from 'utils/format';

import styles from './Price.module.css';

export function Price({ className, price, isCrossed, isVarious }) {
  const value = formatPrice(price);

  return (
    <span className={
      classNames(
        'flex',
        className,
        isCrossed && 'relative text-gray-400'
      )
    }>
      { isVarious && 'от ' }
      { value }
      &nbsp;<span>₽</span>
      { isCrossed && <div className={styles.crossed}/> }
    </span>
  )
}

