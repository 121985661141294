import { useEffect, useState } from 'react'
// ---
export const usePopState = ({ onPopped }) => {
  const [
    isStatePopped,
    setStatePopped,
  ] = useState(false)

  useEffect(() => {
    window.history.pushState(
      null,
      null,
      window.location.pathname
    )
    window.addEventListener('popstate', onBackButtonEvent)

    // window.onbeforeunload = function () {
    //   return alertDescription
    // }
    return () => {
      window.removeEventListener(
        'popstate',
        onBackButtonEvent
      )
    }
  }, [])

  const onBackButtonEvent = (e) => {
    e.preventDefault()
    if (!isStatePopped) {
      if (onPopped) {
        onPopped()
      }

      setStatePopped(true)
    }
  }

  return {
    isStatePopped,
  }
}
