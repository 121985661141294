import cn from 'classnames';
import { LazyImage } from 'components/common';
import Link from 'next/link';
import { forwardRef } from 'react';


// const Input = forwardRef(({ id, name, value, placeholder, inputClassName, className, ... props }, ref) => {
const CategoryBlock = forwardRef(({ isSpecial, image, className, slug, title, ... props }, ref) => {
  const dominantColor = image?.dominant.join(', ');

  return (
    <li ref={ref} onClick={props.onClick} className={cn('flex flex-col text-sm', className)}>
      <Link
        prefetch={false}
        key={slug}
        href={`/${slug}`}
        aria-label={title}
        className="h-24 min-h-full rounded-md gap-2 items-center"
      >
        <div
          style={{
            background: dominantColor ? `linear-gradient(320deg, rgba(${dominantColor}, 0.1), rgba(${dominantColor}, 0.07))` : '#f1f1f1',
          }}
          className={
            cn(
              'group relative p-4 font-medium h-full flex rounded-xl overflow-hidden',
              // gradient
              isSpecial ? 'bg-gradient-to-br from-green-50/60 to-green-100/60' : 'transition-all'
            )
          }
        >
          <span
            style={{
              maxWidth: '110px',
              fontSize: '16px',
              color: dominantColor ? `rgba(${dominantColor})` : 'black'
            }}
            className="z-10 group-hover:text-blue-600"
          >
            {title}
          </span>
          <div className="absolute w-20 h-20 bottom-0 right-0 overflow-hidden">
            {
              image?.url
                ? (
                  <LazyImage
                    size={92}
                    squared={true}
                    src={image.url}
                    alt={title}
                  />
                ) : (
                  <img src={`/categories-icons/${slug}.png`} />
                )
            }
          </div>
        </div>
      </Link>
    </li>
  )
});

CategoryBlock.displayName = 'CategoryBlock';

export { CategoryBlock };
