import APIService from './api.service';


class CitiesService extends APIService {
  constructor() {
    super({ useServiceToken: true });
  }

  async getMany() {
    if (process.env.NODE_ENV === 'production' && globalThis.cities) {
      return globalThis.cities;
    }

    globalThis.cities = await this.api.get('/cities');

    return globalThis.cities;
  }

  async getBySlug(slug) {
    return this.api.get(`/cities/${slug}`);
  }
}


export default new CitiesService();
