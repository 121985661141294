import { Button, FormError, InputPhone } from 'components/common';
import { ModalWrapper } from 'components/modals';
import { MODALS } from 'components/modals/constants';
import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { sendForm } from 'services/api/utils.service';
import { getFormattedPhoneNumber } from 'utils';
import { reachGoal } from 'utils/metrika';
import * as yup from 'yup';

const schema = yup.object().shape({
  phone: yup.string().required('Укажите номер телефона').matches(/^\+\d \d{3} \d{3} \d{4}$/, {
    excludeEmptyString: true,
    message: 'Введите полный номер телефона'
  })
});


export const IDENTIFIER = MODALS.CALL;

export function CallModal({ isOpen, setIsOpen }) {
  const [ submittedPhone, setSubmittedPhone ] = useState('');

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => setSubmittedPhone(''), 1000);
    }
  }, [ isOpen ]);

  return (
    <ModalWrapper
      sheetClass="bg-header"
      modalStyle={{ maxWidth: '620px' }}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onDismiss={() => setIsOpen(false)}
    >
      {
        submittedPhone &&
        <div className="absolute h-full w-full bg-white z-20 rounded-2xl flex items-center text-center justify-center flex-col p-10">
          <img src={'/e/270c-fe0f.png'} alt="" width="100px"/>
          <p className="mt-6 text-lg">
            Спасибо!
            <br/>
            Свяжемся по номеру { getFormattedPhoneNumber(submittedPhone) }
          </p>
        </div>
      }
      <div style={{ backgroundImage: 'url(/callback.png)' }} className="h-48 rounded-t-2xl bg-cover bg-center"/>
      <div className="bg-white shadow-xl rounded-b-2xl p-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Оставьте свой номер телефона
        </h3>
        <div className="mt-2">
          <p className="text-gray-500">Свяжемся как можно скорее и решим все вопросы</p>
          <div className="flex gap-2 mt-5 w-full">
            <Formik
              initialValues={{
                phone: ''
              }}
              validationSchema={schema}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={({ phone }) => {
                reachGoal('callback_applied', {
                  phone_number: phone
                });

                sendForm('callback', {
                  phoneNumber: phone
                })
                  .then(() => {
                    setSubmittedPhone(phone);
                  });
              }}
            >
              <Form className="w-full">
                <InputPhone name="phone" placeholder="Ваш телефон"/>
                <ErrorMessage name="phone" component={FormError}/>
                <Button type="submit" className="mt-4 w-full" color="blue">Отправить</Button>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}
