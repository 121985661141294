import { autoUpdate, useFloating } from '@floating-ui/react-dom';
import { FloatingPortal } from '@floating-ui/react-dom-interactions';
import cn from 'classnames';
import { cloneElement, useState } from 'react';


export function HoverPopover({
  target,
  children,
  selector,
  strategy = 'fixed',
  placement = 'bottom-start',
  floatingOptions = {},
  offsetTop = 0,
  offsetLeft = 0,
  usePortal = true
}) {
  const { x, y, reference, floating, strategy: positionStrategy } = useFloating({
    // middleware: [ computePosition() ],
    // whileElementsMounted: autoUpdate,
    whileElementsMounted: (reference, floating, update) => {
      // IMPORTANT: Make sure the cleanup function is returned!
      return autoUpdate(reference, floating, update, {
        animationFrame: true,
      });
    },
    strategy,
    placement,
    ... floatingOptions
  });

  const [ timeoutId, setTimeoutId ] = useState(null);
  const [ isVisible, setVisible ] = useState(false);


  const hidePopover = () => {
    if (!timeoutId) {
      setTimeoutId(setTimeout(() => setVisible(false), 500));
    }
  };

  const showPopover = () => {
    if (timeoutId) {
      clearInterval(+timeoutId);
      setTimeoutId(null);
    }

    setVisible(true);
  }

  return (
    <>
      {
        cloneElement(target, {
          onMouseEnter: () => showPopover(),
          onMouseLeave: () => hidePopover(),
          ref: reference
        })
      }
      <FloatingPortal>
        {/*{*/}
        {/*  isVisible &&*/}
        <div
          ref={ floating }
          className="z-30"
          style={ {
            visibility: isVisible ? 'visible' : 'hidden',
            position: positionStrategy,
            top: y + offsetTop ?? 0,
            left: x + offsetLeft ?? 0,
          } }
        >
          <div
            onMouseEnter={() => showPopover()}
            onMouseLeave={() => hidePopover()}
            className={cn('transition-all', isVisible ? 'opacity-100' : 'opacity-0')}
          >
            { children }
          </div>
        </div>
        {/*}*/}
      </FloatingPortal>
    </>
  )
}
