export function SlideIndicator({ progress }) {
  return (
    <div className="relative bg-white bg-opacity-30 h-1 rounded w-full">
      <div
        style={{
          ... (progress !== 0 && progress !== 100) && {
            transitionDelay: 'none',
            transitionDuration: '0.25s',
            transitionProperty: 'width',
            transitionTimingFunction: 'linear',
          },
          width: `${progress}%`
        }}
        className="absolute h-full rounded bg-white bg-opacity-40"
      />
    </div>
  )
}
