import cookies from 'js-cookie';


const formatName = (name) => name.startsWith('flopus_')
  ? name
  : `flopus_${name}`;

export const get = (name, defaultValue) => {
  const value = cookies.get(formatName(name));

  if (!value) {
    return defaultValue;
  }

  try {
    return JSON.parse(value);
  } catch {
    return (typeof defaultValue === 'object' && typeof value !== 'object')
      ? defaultValue
      : value;
  }
};

export const set = (name, value) => {
  const transformedValue = (typeof value === 'object')
    ? JSON.stringify(value)
    : value;

  return cookies.set(formatName(name), transformedValue, {
    domain: process.env.NEXT_PUBLIC_DOMAIN,
    expires: 365
  });
};

export const remove = (name) => cookies.remove(formatName(name), {
  domain: process.env.NEXT_PUBLIC_DOMAIN,
  expires: 0
});
