import { RectangleStackIcon, XMarkIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';

export function HeaderCatalogButton({ large, isOpen, className, ...rest }) {
  const Icon = isOpen ? XMarkIcon : RectangleStackIcon;

  return (
    <button
      aria-label="Каталог"
      color="transparent"
      data-dropdown={true}
      className={
        cn(
          'hidden md:flex text-blue-600 font-medium items-center',
          large ? 'text-lg gap-3 text-blue-700' : 'gap-2 text-blue-600',
          className
        )
      }
      { ...rest }
    >
      <Icon className={cn('text-blue-600', large ? 'w-8 h-8': 'w-6 h-6')}/>
      Каталог
    </button>
  )
}
