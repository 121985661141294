import { Button, FormError, Input, InputPhone } from 'components/common';
import { MODALS } from 'components/modals/constants';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useCart, useUpdateCart } from 'query/orders';
import { useEffect } from 'react';
import { reachGoal } from 'utils/metrika';
import * as yup from 'yup';


const schema = yup.object().shape({
  name: yup.string(),
  phoneNumber: yup.string().matches(/^\+\d \d{3} \d{3} \d{4}$/, {
    excludeEmptyString: true,
    message: 'Введите полный номер телефона'
  })
});


export const IDENTIFIER = MODALS.DELIVERY_RECEIVER;

export function DeliveryReceiverModal({ onChange }) {
  const { mutate, isLoading, isSuccess } = useUpdateCart();
  const { data } = useCart();

  useEffect(() => {
    if (isSuccess === true) {
      onChange();
    }
  }, [ isSuccess ]);

  if (!data.uuid) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        name: data?.createdBy?.fullName || '',
        phoneNumber: data?.createdBy?.phoneNumber || ''
      }}
      validationSchema={schema}
      onSubmit={({ name, phoneNumber }) => {
        if (phoneNumber) {
          reachGoal('update_receiver_info', {
            order_id: data.uuid,
            phone_number: phoneNumber
          });
        }

        mutate({
          uuid: data.uuid,
          receiverName: name || '',
          receiverPhoneNumber: phoneNumber || ''
        });
      }}
    >
      <Form>
        <div className="flex flex-col gap-3">
          {/*{ JSON.stringify(state) }*/}
          <h3>Контакты получателя</h3>
          <p className="mb-3">Мы не звоним получателю с вопросами по заказу, контакты могут понадобиться только при получении</p>
          <Field as={Input} name="name" placeholder="Имя получателя"/>
          <InputPhone name="phoneNumber" placeholder="Телефон получателя"/>
          <ErrorMessage name="phoneNumber" component={FormError}/>

          <Button
            color="blue"
            type="submit"
            disabled={isLoading}
          >
            Сохранить
          </Button>
        </div>
      </Form>
    </Formik>
  )
}

