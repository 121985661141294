import { CheckIcon } from '@heroicons/react/24/solid';
import cn from 'classnames';
import { MODALS } from 'components/modals/constants';
import { ENABLED_PAYMENT_METHODS } from 'utils/constants';
import { reachGoal } from 'utils/metrika';


export const IDENTIFIER = MODALS.PAYMENT_METHOD_MOBILE;

export function PaymentMethodMobileModal({ current, onSelect }) {
  return (
    <div className="flex flex-col px-2 py-4 gap-2">
      {
        ENABLED_PAYMENT_METHODS.map(method => {
          return (
            <div
              className="p-3 rounded-xl flex gap-2"
              key={method.id}
              onClick={() => {
                reachGoal('payment_method_updated', {
                  method: method.name
                });

                onSelect(method);
              }}
            >
              <div className="flex items-center gap-3 w-full">
                {
                  current.id === method.id && <CheckIcon className="w-5 h-5 text-blue-600"/>
                }
                <div className={cn('font-medium', current.id === method.id && 'text-blue-600')}>{ method.name }</div>
              </div>
              <div className="flex justify-center items-center">{ method.icon }</div>
            </div>
          )
        })
      }
    </div>
  )
}
