import { Drawer } from 'components/modals';
import { MobileCartContext } from 'context';
import { useState } from 'react';

import { CartCheckoutScreen, CartDetailsScreen } from './screens';


export const CartMobileModalV2 = ({ visible, onClose }) => {
  const [ currentScreen, setCurrentScreen ] = useState('details');

  return (
    <Drawer
      onClose={() => onClose()}
      visible={visible}
      dragDisabled={true}
      titleComponent={true}
      maxSize="calc(100% - 66px)"
    >
      <MobileCartContext.Provider
        value={{
          screen: currentScreen,
          setCurrentScreen
        }}
      >
        {
          currentScreen === 'details' &&
          <CartDetailsScreen/>
        }
        {
          currentScreen === 'checkout' &&
          <CartCheckoutScreen/>
        }
      </MobileCartContext.Provider>
    </Drawer>
  )
}
