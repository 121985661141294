import { Button, QtyModificator } from 'components/common';
import { ModalWrapper, OneClickOrderModal } from 'components/modals';
import { useAddProduct, useCart, useEditProduct } from 'query/orders';
import { useState } from 'react';
import { reachGoal } from 'utils/metrika';

export function ProductCartActionButton({ size = 'lg', onProductAdded, product }) {
  const { isError, isLoading, data } = useCart();
  const [ isOneCLickModalOpen, setOneCLickModalOpen ] = useState(false);
  const currentProductInCart = !isLoading && data?.items?.find(item => item.product.id === product.id);
  const { mutate: editProductMutation, isLoading: isEditFetching, isSuccess: isEditProductMutationSuccess } = useEditProduct();
  const { mutate: addProductMutation, isLoading: isAddingFetching, isSuccess: isAddingSuccess } = useAddProduct({
    onSuccess: () => {
      if (onProductAdded) {
        onProductAdded(product);
      }
    }
  });

  // useEffect(() => {
  //   console.log('asdasd', isAddingSuccess, onProductAdded)
  //   if (isAddingSuccess && onProductAdded) {
  //
  //   }
  // }, [ onProductAdded, isAddingSuccess ]);

  const addToCart = (e) => {
    console.log('add to cart')
    reachGoal('cart_added_product', {
      product_id: product.id,
      title: product.title,
      source: 'product_card'
    });

    addProductMutation({
      productId: product.id,
      qty: 1
    });
  }

  const editCartItem = (newQty) => {
    if (newQty === 0) {
      reachGoal('cart_removed_product', {
        product_id: product.id,
        title: product.title,
        source: 'product_card'
      });
    }

    editProductMutation({
      itemUUID: currentProductInCart.uuid,
      qty: newQty
    });
  }

  return (
    <>
      {
        (!product.outOfStock && !currentProductInCart) &&
        <Button
          aria-label="Добавить в корзину"
          sx="w-full"
          color="blue"
          disabled={isAddingFetching}
          onClick={() => addToCart()}
        >
          Добавить в корзину
        </Button>
      }
      {
        product.outOfStock &&
        <Button
          className="w-full"
          color="white"
          onClick={() => setOneCLickModalOpen(true)}
        >
          Заказать похожий
        </Button>
      }
      {
        (!product.outOfStock && currentProductInCart) &&
        <div className="flex justify-end">
          <QtyModificator
            size={size}
            className="w-full"
            currentQty={currentProductInCart.qty}
            disabled={isEditFetching || isAddingFetching}
            onQtyChanged={newQty => editCartItem(newQty)}
          />
        </div>
      }
      <ModalWrapper
        modalStyle={{ maxWidth: '500px' }}
        isOpen={isOneCLickModalOpen}
        setIsOpen={setOneCLickModalOpen}
        titleComponent={true}
        onDismiss={() => setOneCLickModalOpen(false)}
      >
        <OneClickOrderModal
          product={product}
          onClose={() => setOneCLickModalOpen(false)}
        />
      </ModalWrapper>
    </>
  )
}
