import cn from 'classnames';
import { CategoryIcon } from 'components/categories';
import Link from 'next/link';

import styles from './CategoriesListItem.module.css';


export function CategoriesListItem({ className, isSpecial, slug, title, ... props }) {
  return (
    <li className={cn(styles.col, className)}>
      <Link
        prefetch={false}
        key={slug}
        href={`/${slug}`}
        aria-label={title}
        className={cn('group', styles.link)}
        { ... props }
      >
        <CategoryIcon slug={slug} isSpecial={isSpecial}/>
        <span className="flex-1 truncate group-hover:text-blue-600">{ title }</span>
      </Link>
    </li>
  )
}
